var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "img-area" },
    [
      _c("PuSkeleton", {
        staticClass: "img-slider",
        attrs: {
          loading: true,
          width: _vm.width_,
          height: _vm.loadImgCnt == _vm.data.length ? "35.4rem" : "0"
        }
      }),
      _c(
        "div",
        {
          staticClass: "img-slider",
          class: { "image-none": _vm.loadImgCnt == _vm.data.length }
        },
        [
          _c(
            "ul",
            { ref: "slick", style: { height: _vm.height } },
            _vm._l(_vm.data, function(row, key) {
              return _c("li", {
                key: key,
                staticClass: "ui-bg-img",
                style: {
                  backgroundImage: "url(" + _vm.$lib.cdnUrl(row) + ")",
                  backgroundPosotion: "center",
                  width: _vm.width_,
                  height: _vm.height
                },
                on: {
                  click: function() {
                    return _vm.$refs.modal.show(key)
                  }
                }
              })
            }),
            0
          ),
          _c("div", { staticClass: "current-no" }, [
            _c("span", { staticClass: "current-page" }, [
              _vm._v(_vm._s(_vm.currentNo))
            ]),
            _vm._v(" / " + _vm._s(_vm.data.length))
          ]),
          _c(
            "div",
            { staticClass: "btn-left-arrow", on: { click: _vm.slickPrev } },
            [
              _c("icon", {
                attrs: {
                  src: "img/common/btn-left-arrow.png",
                  width: "4rem",
                  height: "4rem",
                  cover: false
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btn-right-arrow", on: { click: _vm.slickNext } },
            [
              _c("icon", {
                attrs: {
                  src: "img/common/btn-right-arrow.png",
                  width: "4rem",
                  height: "4rem",
                  cover: false
                }
              })
            ],
            1
          ),
          _c("PageModal", { ref: "modal" }, [
            _c(
              "div",
              {
                style: { margin: "0 -3rem" },
                attrs: { slot: "body" },
                slot: "body"
              },
              [
                _vm._l(_vm.data, function(row, key) {
                  return [
                    _c("img", {
                      key: key,
                      attrs: {
                        src: _vm.$lib.cdnUrl(row),
                        alt: "image " + (key + 1),
                        width: "100%"
                      }
                    })
                  ]
                })
              ],
              2
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }