var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a-profile" },
    [
      _vm.isEarlybirdEventArtist
        ? [
            _c("div", { staticClass: "artistEvent" }, [
              _vm._v(
                " " +
                  _vm._s(Math.round(_vm.artistData.salePer || 0)) +
                  "%할인 - " +
                  _vm._s(_vm.earlybirdEventTimer) +
                  " 남음 "
              )
            ])
          ]
        : _vm._e(),
      _c(
        "div",
        { staticClass: "profile-header" },
        [
          _c(
            "div",
            { staticClass: "profile-btn" },
            [
              !_vm.isPreviewOfArtistProfile
                ? [
                    _c("icon", {
                      staticClass: "icon ui-btn share",
                      attrs: {
                        src: "img/common/share.png",
                        width: "3.2rem",
                        height: "3.7rem",
                        cover: false
                      },
                      on: {
                        click: function() {
                          return _vm.artistShare()
                        }
                      }
                    })
                  ]
                : _vm._e(),
              _vm.isClient
                ? [
                    _vm.artistData.clientFav > 0
                      ? _c("icon", {
                          staticClass: "icon ui-btn like",
                          attrs: {
                            src: "img/common/liked2.png",
                            width: "3.7rem",
                            height: "3.5rem",
                            cover: false
                          },
                          on: {
                            click: function() {
                              return _vm.setLike(false)
                            }
                          }
                        })
                      : _c("icon", {
                          staticClass: "icon ui-btn like",
                          attrs: {
                            src: "img/common/like.png",
                            width: "3.7rem",
                            height: "3.5rem",
                            cover: false
                          },
                          on: {
                            click: function() {
                              return _vm.setLike(true)
                            }
                          }
                        })
                  ]
                : _vm._e()
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "profile-name", style: { paddingRight: "100px" } },
            [_vm._v(" " + _vm._s(_vm.artistData.teamName) + " ")]
          ),
          _c("div", { staticClass: "profile-genre-location" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$lib.t_genre(
                    _vm.artistData.categoryType != "supplies"
                      ? _vm.artistData.genre
                      : _vm.artistData.categoryName
                  )
                )
            ),
            _c("span", [_vm._v("·")]),
            _vm._v(
              _vm._s(_vm.$lib.getLocationText(_vm.artistData.location)) + " "
            )
          ]),
          _vm.artistData.viewCount > 0
            ? _c(
                "div",
                { staticClass: "profile-viewCount" },
                [
                  _vm.artistData.favCount > 0
                    ? [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$lib.addComma(_vm.artistData.viewCount)
                            ) + "명"
                          )
                        ]),
                        _vm._v("이 봤고, "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$lib.addComma(_vm.artistData.favCount)) +
                              "명"
                          )
                        ]),
                        _vm._v("이 찜했어요. ")
                      ]
                    : [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$lib.addComma(_vm.artistData.viewCount)
                            ) + "명"
                          )
                        ]),
                        _vm._v("이 봤어요. ")
                      ]
                ],
                2
              )
            : _vm._e(),
          !_vm.isCusOrder
            ? [
                (_vm.isCustomArtist || _vm.isFluctuatingStart) &&
                !/^\/client\/my-offer/.test(_vm.$route.path)
                  ? _c(
                      "div",
                      { staticClass: "profile-price" },
                      [
                        _vm.isDiscountEventCustomArtist
                          ? [
                              _c("span", { staticClass: "discount" }, [
                                _vm._v(
                                  _vm._s(
                                    Math.round(_vm.artistData.salePer || 0)
                                  ) + "% "
                                )
                              ]),
                              _c("span", { staticClass: "canceled" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$lib.addComma(
                                      _vm.artistData.cusPriceStart
                                    )
                                  ) + "원"
                                )
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$lib.addComma(
                                      _vm.$lib.floor(
                                        Number(_vm.artistData.cusPriceStart) -
                                          Math.floor(
                                            (Number(
                                              _vm.artistData.cusPriceStart
                                            ) *
                                              Number(_vm.artistData.salePer)) /
                                              100
                                          ),
                                        2
                                      )
                                    )
                                  ) + "원"
                                )
                              ])
                            ]
                          : _vm.isFluctuating
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$lib.addComma(
                                      _vm.artistData.cusPriceStart
                                    )
                                  ) +
                                  "원 ~ " +
                                  _vm._s(
                                    _vm.$lib.addComma(
                                      _vm.artistData.cusPriceEnd
                                    )
                                  ) +
                                  "원 "
                              )
                            ]
                          : _vm.isFluctuatingStart
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$lib.addComma(
                                      _vm.artistData.cusPriceStart
                                    )
                                  ) +
                                  "원 ~ "
                              )
                            ]
                          : _vm.artistData.cusPriceStart > 0
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$lib.addComma(
                                      _vm.artistData.cusPriceStart
                                    )
                                  ) +
                                  "원 "
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "profile-vat-price" },
                  [
                    _vm.artistData.includeVatFlag == "n" &&
                    this.artistData.priceType
                      ? [
                          _c(
                            "span",
                            { staticStyle: { "min-width": "fit-content" } },
                            [_vm._v("*부가세 별도")]
                          ),
                          _vm.addWorkName
                            ? _c("span", { staticClass: "add-work-price" }, [
                                _vm._v(
                                  _vm._s(_vm.addWorkName) +
                                    _vm._s(
                                      _vm.artistData.addWorkPrice > 0
                                        ? "(+ " +
                                            _vm.$lib.addComma(
                                              _vm.artistData.addWorkPrice
                                            ) +
                                            "원)"
                                        : ""
                                    )
                                )
                              ])
                            : _vm._e()
                        ]
                      : _vm.addWorkName
                      ? [
                          _vm._v(
                            _vm._s(_vm.addWorkName) +
                              _vm._s(
                                _vm.artistData.addWorkPrice > 0
                                  ? "(+ " +
                                      _vm.$lib.addComma(
                                        _vm.artistData.addWorkPrice
                                      ) +
                                      "원)"
                                  : ""
                              )
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm.offerUid &&
      (Number(_vm.artistData.proPrice) ||
        _vm.artistData.proExt1 ||
        _vm.artistData.proExt2 ||
        _vm.artistData.proExt3 ||
        (_vm.isRejectedOffer && _vm.artistData.proBodyText))
        ? _c("div", { staticClass: "profile-info" }, [
            _c("div", { staticClass: "profile-info-ticket type-flex-sb" }, [
              _vm.isRejectedOffer && _vm.artistData.proBodyText
                ? _c("div", { staticClass: "info-block" }, [
                    _c("ul", [
                      _c("li", [_vm._v("섭외 답변")]),
                      _c("li", [_vm._v(_vm._s(_vm.artistData.proBodyText))])
                    ]),
                    _c("div", { staticClass: "ui-border-line" })
                  ])
                : _vm._e(),
              _vm.artistData.proExt1
                ? _c("div", { staticClass: "info-block" }, [
                    _c("ul", [
                      _c("li", [_vm._v("출장비")]),
                      _c("li", [_vm._v(_vm._s(_vm.artistData.proExt1))])
                    ])
                  ])
                : _vm._e(),
              _vm.artistData.proExt2
                ? _c("div", { staticClass: "info-block" }, [
                    _c("ul", [
                      _c("li", [_vm._v("동일 의뢰 경험")]),
                      _c("li", [_vm._v(_vm._s(_vm.artistData.proExt2))])
                    ])
                  ])
                : _vm._e(),
              _vm.artistData.proExt8
                ? _c("div", { staticClass: "info-block" }, [
                    _c("ul", [
                      _c("li", [_vm._v("공연 구성")]),
                      _c("li", [_vm._v(_vm._s(_vm.artistData.proExt8))])
                    ])
                  ])
                : _vm._e(),
              _vm.artistData.proExt7
                ? _c("div", { staticClass: "info-block" }, [
                    _c("ul", [
                      _c("li", [_vm._v("참여인원")]),
                      _c("li", [_vm._v(_vm._s(_vm.artistData.proExt7))])
                    ])
                  ])
                : _vm._e(),
              _vm.artistData.proExt6
                ? _c("div", { staticClass: "info-block" }, [
                    _c("ul", [
                      _c("li", [_vm._v("제공 서비스")]),
                      _c("li", [_vm._v(_vm._s(_vm.artistData.proExt6))])
                    ])
                  ])
                : _vm._e(),
              _vm.usingExt5
                ? _c("div", { staticClass: "info-block" }, [
                    _c("ul", [
                      _vm.$lib.validMultipleGenre(_vm.offerData.genre, "노래")
                        ? _c("li", [_vm._v("MR 제공")])
                        : _vm._e(),
                      _vm.$lib.validMultipleGenre(_vm.offerData.genre, "MC")
                        ? _c("li", [_vm._v("식순, 대본 협의")])
                        : _vm._e(),
                      _c("li", [_vm._v(_vm._s(_vm.artistData.proExt5))])
                    ])
                  ])
                : _vm._e(),
              _vm.artistData.proExt4
                ? _c("div", { staticClass: "info-block" }, [
                    _c("ul", [
                      _c("li", [_vm._v("미팅 방식")]),
                      _c("li", [
                        _vm._v(
                          _vm._s(_vm.artistData.proExt4.replaceAll(" 미팅", ""))
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              Number(_vm.artistData.proPrice) > 0
                ? _c("div", { staticClass: "info-block block-price" }, [
                    _c("div", { staticClass: "block-price-top" }, [
                      _vm._v("*부가세 별도")
                    ]),
                    _c("ul", [
                      _c("li", [_vm._v("견적 금액")]),
                      _c(
                        "li",
                        [
                          _vm.isEarlybirdEventArtist
                            ? [
                                _c("span", { staticClass: "original-price" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$lib.addComma(
                                        Number(_vm.artistData.proPrice) || "0"
                                      )
                                    ) + "원"
                                  )
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ui-weight-6",
                                    staticStyle: {
                                      "font-size": "3rem",
                                      "line-height": "4.2rem",
                                      color: "#ab54bb"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$lib.addComma(
                                            Number(_vm.estimatedAmount) || "0"
                                          )
                                        ) +
                                        "원"
                                    )
                                  ]
                                )
                              ]
                            : [
                                _c(
                                  "span",
                                  {
                                    staticClass: "ui-weight-6",
                                    staticStyle: {
                                      "font-size": "3rem",
                                      "line-height": "4.2rem",
                                      color: "#ab54bb"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$lib.addComma(
                                          Number(_vm.estimatedAmount) || "0"
                                        )
                                      ) + "원"
                                    )
                                  ]
                                )
                              ]
                        ],
                        2
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e(),
      [
        _vm.offerUid &&
        Number(_vm.artistData.proPrice) &&
        !_vm.isRejectedOffer &&
        _vm.artistData.proBodyText
          ? _c(
              "div",
              { staticClass: "profile-teamDesc", class: { full: true } },
              [
                _c("h4", [_vm._v("지원자의 한마디")]),
                _c("div", { staticClass: "teamDesc-block" }, [
                  _c("div", { staticClass: "teamDesc-text" }, [
                    _vm._v(_vm._s(_vm.artistData.proBodyText))
                  ])
                ])
              ]
            )
          : _vm._e()
      ],
      !!_vm.artistData.eventMemo
        ? [
            _c(
              "div",
              {
                staticClass: "profile-teamDesc",
                class: { full: _vm.showEventMemoAllText }
              },
              [
                _c(
                  "badge",
                  {
                    style: { marginTop: "6px" },
                    attrs: { type: "artistCheck" }
                  },
                  [_vm._v("이벤트")]
                ),
                _c("div", [
                  _c(
                    "div",
                    { ref: "EventMemo", staticClass: "teamDesc-text" },
                    [_vm._v(_vm._s(_vm.artistData.eventMemo))]
                  ),
                  _vm.isOverEventMemoMinHeight && !_vm.showEventMemoAllText
                    ? _c(
                        "div",
                        [
                          _c("icon", {
                            style: { margin: "20px auto 15px" },
                            attrs: {
                              src: "img/artist/btn-info-more.svg",
                              width: "28px",
                              height: "28px",
                              cover: false
                            },
                            on: {
                              click: function() {
                                _vm.showEventMemoAllText = !_vm.showEventMemoAllText
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.$route.name === "RecruiterArtistProfile"
        ? [
            _c("div", { staticClass: "thumbs-up" }, [
              _c(
                "div",
                {
                  staticClass: "thumbs-up--box",
                  on: {
                    click: function($event) {
                      return _vm.$gotoWeb("https://bit.ly/3EmJUZY")
                    }
                  }
                },
                [
                  _c("icon", {
                    staticClass: "thumbs-up--icon",
                    attrs: {
                      src: "img/recruiter/thumbs-up.png",
                      width: "27px",
                      height: "29px"
                    }
                  }),
                  _vm._v(" 리뷰 남기고 최대 50,000원 받아 가세요! "),
                  _c("span", { staticClass: "thumbs-up--btn" }, [_vm._v(">")])
                ],
                1
              )
            ])
          ]
        : _vm._e(),
      !_vm.isPreview &&
      (_vm.isClient || _vm.isGuest || _vm.artistData.reviewCount > 0)
        ? [
            _c(
              "div",
              {
                staticClass: "review",
                style:
                  _vm.artistData.reviewCount > 0
                    ? { padding: "2rem 0 0" }
                    : { padding: "0.5rem 0" }
              },
              [
                _vm.artistData.reviewCount == 0
                  ? [
                      _c("h4", [
                        _vm._v("리뷰 "),
                        _vm.isClient || _vm.isGuest
                          ? _c(
                              "button",
                              {
                                staticClass: "color-black",
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push(
                                      "/artist/profile/" +
                                        _vm.artistData.artistidx +
                                        "/write-review"
                                    )
                                  }
                                }
                              },
                              [_vm._v("등록하기 >")]
                            )
                          : _vm._e()
                      ])
                    ]
                  : [
                      _c("h4", [
                        _vm._v(" 리뷰 "),
                        _c("span", { staticStyle: { "font-weight": "400" } }, [
                          _vm._v(
                            "(" + _vm._s(_vm.artistData.reviewCount) + "명)"
                          )
                        ]),
                        _vm._v(" "),
                        _vm.isClient || _vm.isGuest
                          ? _c(
                              "button",
                              {
                                staticClass: "color-lavender",
                                on: {
                                  click: function() {
                                    return _vm.$router.push(
                                      "/artist/profile/" +
                                        _vm.artistData.artistidx +
                                        "/write-review"
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "리뷰 " +
                                    _vm._s(
                                      _vm.myReviewData && !_vm.isGuest
                                        ? "수정"
                                        : "쓰기"
                                    )
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _c("div", {
                        staticClass: "ui-border-line ui-h-0 ui-mb-3"
                      }),
                      _c("AReview", {
                        attrs: {
                          artistData: _vm.artistData,
                          reviewItemData: _vm.reviewItemData,
                          reviewData: _vm.reviewData
                        }
                      }),
                      _c("div", {
                        staticClass: "ui-border-line ui-h-0 ui-mb-2"
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "write-btn",
                          on: {
                            click: function() {
                              return _vm.gotoReviewList()
                            }
                          }
                        },
                        [_vm._v(" 전체 리뷰 보기 > ")]
                      )
                    ]
              ],
              2
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1 ui-mt-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.isProfile
        ? [
            _c(
              "div",
              { staticClass: "info-estimate d-flex justify-content-between" },
              [
                _c("div"),
                _vm.artistData.checkDate > 0
                  ? [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "700",
                            color: "#ab54bb"
                          }
                        },
                        [_vm._v("신원 인증")]
                      )
                    ]
                  : [_c("div", [_vm._v("신원 미인증")])],
                _c("div", {
                  staticClass: "ui-border-line",
                  style: { width: "1px", height: "auto", margin: 0 }
                }),
                _c("div", [
                  _vm._v("노쇼,불참 "),
                  _c(
                    "span",
                    { staticStyle: { "font-weight": "700", color: "#ab54bb" } },
                    [
                      _vm._v(
                        _vm._s(Number(_vm.artistData.noshowCount) || 0) + "건"
                      )
                    ]
                  )
                ]),
                _c("div", {
                  staticClass: "ui-border-line",
                  style: { width: "1px", height: "auto", margin: 0 }
                }),
                _c("div", [
                  _vm._v("취소율 "),
                  _c(
                    "span",
                    { staticStyle: { "font-weight": "700", color: "#ab54bb" } },
                    [
                      _vm._v(
                        _vm._s(
                          (
                            (Number(_vm.artistData.cancelCount) /
                              Number(_vm.artistData.offerPayCount)) *
                              100 || 0
                          ).toFixed(0)
                        ) + "%"
                      )
                    ]
                  )
                ]),
                _c("div")
              ],
              2
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.hasProfileInfo
        ? _c(
            "div",
            {
              staticClass: "profile-info",
              staticStyle: { padding: "2rem 0 0" }
            },
            [
              _c("h4", { staticClass: "d-flex" }, [
                _vm._v(" 기본 정보 "),
                _vm.artistData.career && _vm.artistData.career > 0
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.artistData.career > 1 ? "경력: " : "") +
                            _vm.$config.constant.career[_vm.artistData.career]
                        )
                      )
                    ])
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "profile-info-basic" },
                [
                  _vm.artistData.subGenre &&
                  _vm.$lib.validMultipleGenre(
                    _vm.artistData.genre,
                    "노래",
                    "MC",
                    "쇼호스트",
                    "모델",
                    "연주",
                    "댄스",
                    "강연자"
                  )
                    ? _c(
                        "div",
                        {
                          staticClass: "info-block",
                          class: { full: _vm.isSubGenreOpen }
                        },
                        [
                          _c("ul", { staticClass: "d-flex" }, [
                            _c("li", [_vm._v("세부 분야")]),
                            _vm._v(" "),
                            _c(
                              "li",
                              _vm._l(
                                _vm.artistData.subGenre
                                  .split(/,\s?/)
                                  .filter(function(row) {
                                    return row != "기타"
                                  }),
                                function(genre, key) {
                                  return _c("span", { key: key }, [
                                    _vm._v(" " + _vm._s(genre) + " ")
                                  ])
                                }
                              ),
                              0
                            )
                          ]),
                          _vm.artistData.subGenre.split(/,\s?/).length > 10
                            ? _c(
                                "div",
                                { staticClass: "info-more" },
                                [
                                  _c("icon", {
                                    style: { margin: "16px auto 0" },
                                    attrs: {
                                      src: "img/artist/btn-info-more.svg",
                                      width: "28px",
                                      height: "28px",
                                      cover: false
                                    },
                                    on: {
                                      click: function() {
                                        _vm.isSubGenreOpen = !_vm.isSubGenreOpen
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _vm.$lib.validMultipleGenre(_vm.artistData.genre, "노래")
                    ? [
                        _vm.artistData.teamType
                          ? _c(
                              "div",
                              {
                                staticClass: "info-block",
                                class: { full: _vm.isTeamTypeOpen }
                              },
                              [
                                _c("ul", { staticClass: "d-flex" }, [
                                  _c("li", [_vm._v("팀구성")]),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    _vm._l(
                                      _vm.artistData.teamType.split(/,\s?/),
                                      function(teamType, key) {
                                        return _c("span", { key: key }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$config.constant.teamType[
                                                teamType
                                              ]
                                            )
                                          )
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ]),
                                _vm.artistData.teamType.split(/,\s?/).length >
                                10
                                  ? _c(
                                      "div",
                                      { staticClass: "info-more" },
                                      [
                                        _c("icon", {
                                          style: { margin: "16px auto 0" },
                                          attrs: {
                                            src: "img/artist/btn-info-more.svg",
                                            width: "28px",
                                            height: "28px",
                                            cover: false
                                          },
                                          on: {
                                            click: function() {
                                              _vm.isTeamTypeOpen = !_vm.isTeamTypeOpen
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e(),
                        _vm.artistData.matchVoice
                          ? _c(
                              "div",
                              {
                                staticClass: "info-block",
                                class: { full: _vm.isMatchVoiceOpen }
                              },
                              [
                                _c("ul", { staticClass: "d-flex" }, [
                                  _c("li", [_vm._v("닮은 목소리")]),
                                  _c(
                                    "li",
                                    _vm._l(
                                      _vm.artistData.matchVoice.split("|"),
                                      function(voice, key) {
                                        return _c("span", { key: key }, [
                                          _vm._v(_vm._s(voice))
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ]),
                                _vm.artistData.matchVoice.split("|").length > 10
                                  ? _c(
                                      "div",
                                      { staticClass: "info-more" },
                                      [
                                        _c("icon", {
                                          style: { margin: "16px auto 0" },
                                          attrs: {
                                            src: "img/artist/btn-info-more.svg",
                                            width: "28px",
                                            height: "28px",
                                            cover: false
                                          },
                                          on: {
                                            click: function() {
                                              _vm.isMatchVoiceOpen = !_vm.isMatchVoiceOpen
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e(),
                        _vm.artistData.recommSong
                          ? _c(
                              "div",
                              {
                                staticClass: "info-block",
                                class: { full: _vm.isRecommSongOpen }
                              },
                              [
                                _c("ul", { staticClass: "d-flex" }, [
                                  _c("li", [_vm._v("자신있는 곡")]),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    { staticClass: "single-line" },
                                    _vm._l(
                                      _vm.artistData.recommSong.split("|"),
                                      function(song, key) {
                                        return _c("span", { key: key }, [
                                          _vm._v(_vm._s(song))
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ]),
                                _vm.artistData.recommSong.split("|").length > 5
                                  ? _c(
                                      "div",
                                      { staticClass: "info-more" },
                                      [
                                        _c("icon", {
                                          style: { margin: "16px auto 0" },
                                          attrs: {
                                            src: "img/artist/btn-info-more.svg",
                                            width: "28px",
                                            height: "28px",
                                            cover: false
                                          },
                                          on: {
                                            click: function() {
                                              _vm.isRecommSongOpen = !_vm.isRecommSongOpen
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e()
                      ]
                    : _vm._e(),
                  [
                    _vm.artistData.mcStyle &&
                    _vm.$lib.validMultipleGenre(
                      _vm.artistData.genre,
                      "MC",
                      "쇼호스트"
                    )
                      ? _c("div", { staticClass: "info-block" }, [
                          _c("ul", { staticClass: "d-flex" }, [
                            _c("li", [_vm._v("진행 스타일")]),
                            _c(
                              "li",
                              _vm._l(
                                _vm.artistData.mcStyle.split("|"),
                                function(style, key) {
                                  return _c("span", { key: key }, [
                                    _vm._v(_vm._s(style))
                                  ])
                                }
                              ),
                              0
                            )
                          ]),
                          _vm.artistData.mcStyle.split("|").length > 10
                            ? _c(
                                "div",
                                { staticClass: "info-more" },
                                [
                                  _c("icon", {
                                    style: { margin: "16px auto 0" },
                                    attrs: {
                                      src: "img/artist/btn-info-more.svg",
                                      width: "28px",
                                      height: "28px",
                                      cover: false
                                    },
                                    on: {
                                      click: function() {
                                        _vm.isMcStyleOpen = !_vm.isMcStyleOpen
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ],
                  _vm.artistData.showHost &&
                  _vm.$lib.validMultipleGenre(
                    _vm.artistData.genre,
                    "MC",
                    "쇼호스트"
                  )
                    ? _c(
                        "div",
                        {
                          staticClass: "info-block",
                          class: { full: _vm.isShowHostOpen }
                        },
                        [
                          _c("ul", { staticClass: "d-flex" }, [
                            _c("li", [_vm._v("쇼호스트 분야")]),
                            _c(
                              "li",
                              _vm._l(
                                _vm.artistData.showHost.split("|"),
                                function(showhost, key) {
                                  return _c("span", { key: key }, [
                                    _vm._v(_vm._s(showhost) + " ")
                                  ])
                                }
                              ),
                              0
                            )
                          ]),
                          _vm.artistData.showHost.split("|").length > 10
                            ? _c(
                                "div",
                                { staticClass: "info-more" },
                                [
                                  _c("icon", {
                                    style: { margin: "16px auto 0" },
                                    attrs: {
                                      src: "img/artist/btn-info-more.svg",
                                      width: "28px",
                                      height: "28px",
                                      cover: false
                                    },
                                    on: {
                                      click: function() {
                                        _vm.isShowHostOpen = !_vm.isShowHostOpen
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _vm.$lib.validMultipleGenre(_vm.artistData.genre, "모델")
                    ? [
                        _vm.artistData.bodyHeight > 0 ||
                        _vm.artistData.bodyWeight > 0 ||
                        _vm.artistData.bodyShoesize > 0 ||
                        _vm.artistData.bodyTopsize ||
                        _vm.artistData.bodyBottomsize > 0
                          ? _c("div", { staticClass: "info-block" }, [
                              _c("ul", { staticClass: "d-flex" }, [
                                _c("li", [_vm._v("신체정보")]),
                                _c("li", { staticClass: "single-line" }, [
                                  _vm.artistData.bodyHeight > 0
                                    ? _c("span", [
                                        _vm._v(
                                          "키: " +
                                            _vm._s(_vm.artistData.bodyHeight) +
                                            "cm"
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.artistData.bodyWeight > 0
                                    ? _c("span", [
                                        _vm._v(
                                          "몸무게: " +
                                            _vm._s(_vm.artistData.bodyWeight) +
                                            "kg"
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.artistData.bodyShoesize > 0
                                    ? _c("span", [
                                        _vm._v(
                                          "신발 사이즈: " +
                                            _vm._s(
                                              _vm.artistData.bodyShoesize
                                            ) +
                                            "mm"
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.artistData.bodyTopsize
                                    ? _c("span", [
                                        _vm._v(
                                          "상의 사이즈: " +
                                            _vm._s(
                                              _vm.artistData.bodyTopsize.toUpperCase()
                                            )
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.artistData.bodyBottomsize > 0
                                    ? _c("span", [
                                        _vm._v(
                                          "하의 사이즈: " +
                                            _vm._s(
                                              _vm.artistData.bodyBottomsize
                                            ) +
                                            "inch"
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            ])
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm.artistData.program &&
                  _vm.$lib.validMultipleGenre(
                    _vm.artistData.genre,
                    "노래",
                    "MC",
                    "쇼호스트",
                    "댄스",
                    "DJ",
                    "연주",
                    "퍼포먼스",
                    "강연자"
                  )
                    ? _c(
                        "div",
                        {
                          staticClass: "info-block",
                          class: { full: _vm.isProgramOpen }
                        },
                        [
                          _c("ul", { staticClass: "d-flex" }, [
                            _c("li", [_vm._v("방송 출연")]),
                            _c(
                              "li",
                              { staticClass: "single-line" },
                              _vm._l(
                                _vm.artistData.program.split("|"),
                                function(program, key) {
                                  return _c("span", { key: key }, [
                                    _vm._v(_vm._s(program))
                                  ])
                                }
                              ),
                              0
                            )
                          ]),
                          _vm.artistData.program.split("|").length > 5
                            ? _c(
                                "div",
                                { staticClass: "info-more" },
                                [
                                  _c("icon", {
                                    style: { margin: "16px auto 0" },
                                    attrs: {
                                      src: "img/artist/btn-info-more.svg",
                                      width: "28px",
                                      height: "28px",
                                      cover: false
                                    },
                                    on: {
                                      click: function() {
                                        _vm.isProgramOpen = !_vm.isProgramOpen
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ],
                2
              ),
              _c("div", {
                staticClass: "ui-border-line ui-h-1",
                staticStyle: { height: "10px" }
              })
            ]
          )
        : _vm._e(),
      _vm.isFormatHtml || _vm.artistData.teamDescImgUrl
        ? [
            _c(
              "div",
              {
                staticClass: "profile-teamDesc",
                class: { full: _vm.showTeamDescAllText }
              },
              [
                _c("h4", { staticClass: "sr-only" }, [_vm._v("소개")]),
                _vm.isFormatHtml
                  ? _c("div", {
                      ref: "TeamDesc",
                      staticClass: "teamDesc-text ql-editor",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.convertHtml(_vm.artistData.teamDescHtml)
                        )
                      }
                    })
                  : _vm.artistData.teamDescImgUrl
                  ? _c("div", [
                      _c("div", { style: { margin: "0 -3rem" } }, [
                        _c("img", {
                          style: { width: "100%" },
                          attrs: { src: _vm.artistData.teamDescImgUrl }
                        })
                      ]),
                      _vm.isOverTeamDescMinHeight && !_vm.showTeamDescAllText
                        ? _c(
                            "div",
                            [
                              _c("icon", {
                                style: { margin: "20px auto 15px" },
                                attrs: {
                                  src: "img/artist/btn-info-more.svg",
                                  width: "28px",
                                  height: "28px",
                                  cover: false
                                },
                                on: {
                                  click: function() {
                                    _vm.showTeamDescAllText = !_vm.showTeamDescAllText
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ]
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      "movData" in _vm.artistData && _vm.artistData.movData.length
        ? [
            _c(
              "div",
              { staticClass: "profile-video" },
              [
                _c("h4", [_vm._v("동영상")]),
                _c(
                  "ul",
                  [
                    _vm._l(_vm.movData, function(row, key) {
                      return [
                        _c("PuSkeleton", {
                          key: "loading" + key,
                          attrs: {
                            loading: _vm.isYoutubeLoading,
                            width: "48rem",
                            height: "27rem"
                          }
                        }),
                        !_vm.youtube.isHide || key < _vm.youtube.limit
                          ? _c(
                              "li",
                              {
                                key: key,
                                class: { "youtube-none": _vm.isYoutubeLoading }
                              },
                              [
                                _vm.$youtube.getIdFromUrl(row.movUrl) !== null
                                  ? _c("youtube", {
                                      style: {
                                        width: _vm.youtube.width + "rem",
                                        height: _vm.youtube.height + "rem",
                                        marginBottom: "2rem"
                                      },
                                      attrs: {
                                        "video-id": _vm.$youtube.getIdFromUrl(
                                          row.movUrl
                                        ),
                                        "player-vars": { rel: 0, showinfo: 0 }
                                      },
                                      on: { ready: _vm.youtubeReady }
                                    })
                                  : row.movUrl &&
                                    (_vm.isPreview || _vm.isMyProfile)
                                  ? [
                                      _vm.$lib.isAllowMovUrl(row.movUrl)
                                        ? _c("EmbedVideo", {
                                            style: {
                                              marginBottom: "2rem"
                                            },
                                            attrs: { src: row.movUrl }
                                          })
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "youtube-before-upload",
                                              style: {
                                                width:
                                                  _vm.youtube.width + "rem",
                                                height:
                                                  _vm.youtube.height + "rem",
                                                lineHeight:
                                                  _vm.youtube.height + "rem",
                                                marginBottom: "2rem"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " 등록 대기 중인 영상입니다. "
                                              )
                                            ]
                                          )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                ),
                _vm.youtube.isHide && _vm.movData.length > _vm.youtube.limit
                  ? _c("icon", {
                      style: { margin: "20px auto 15px" },
                      attrs: {
                        src: "img/artist/btn-info-more.svg",
                        width: "28px",
                        height: "28px",
                        cover: false
                      },
                      on: {
                        click: function($event) {
                          _vm.youtube.isHide = false
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.isShowNaverLive
        ? [
            _c("div", { staticClass: "profile-naver-live" }, [
              _vm._m(0),
              _c(
                "ul",
                [
                  _vm._l(_vm.artistData.liveMov, function(row, key) {
                    return [
                      row.url && row.image
                        ? _c(
                            "li",
                            {
                              key: key,
                              on: {
                                click: function() {
                                  return _vm.showNaverLiveModal(row)
                                }
                              }
                            },
                            [
                              _c(
                                "PuSkeleton",
                                {
                                  key: key,
                                  attrs: {
                                    loading: _vm.isNaverLiveLoading,
                                    width: "23rem",
                                    height: "28.4rem"
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: { src: _vm.$lib.cdnUrl(row.image) }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ]),
            _c("div", {
              staticClass: "ui-border-line ui-h-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.hasInterviewData
        ? _c("div", [
            _c("div", { staticStyle: { padding: "20px 0" } }, [
              _c("h4", [_vm._v("인터뷰")]),
              _c(
                "div",
                {
                  staticClass: "profile-interview",
                  style: { fontSize: "1.9rem", lineHeight: "1.4em" }
                },
                [
                  _vm.validInterviewAnswer("interview1")
                    ? _c("div", { style: { marginBottom: "30px" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "ui-weight-6",
                            style: { marginBottom: "10px" }
                          },
                          [_vm._v("Q. 당신을 섭외해야 하는 이유를 알려주세요.")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ui-preline",
                            style: { color: "#6f7785" }
                          },
                          [_vm._v(_vm._s(_vm.artistData.interview1))]
                        )
                      ])
                    : _vm._e(),
                  _vm.validInterviewAnswer("interview2")
                    ? _c("div", { style: { marginBottom: "30px" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "ui-weight-6",
                            style: { marginBottom: "10px" }
                          },
                          [
                            _vm._v(
                              "Q. 의뢰자, 대중을 만족시키는 본인만의 노하우는?"
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ui-preline",
                            style: { color: "#6f7785" }
                          },
                          [_vm._v(_vm._s(_vm.artistData.interview2))]
                        )
                      ])
                    : _vm._e(),
                  _vm.validInterviewAnswer("interview3")
                    ? _c("div", { style: { marginBottom: "30px" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "ui-weight-6",
                            style: { marginBottom: "10px" }
                          },
                          [_vm._v("Q. 어떤 전문가로 기억되고 싶으세요?")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ui-preline",
                            style: { color: "#6f7785" }
                          },
                          [_vm._v(_vm._s(_vm.artistData.interview3))]
                        )
                      ])
                    : _vm._e()
                ]
              )
            ]),
            _c("div", {
              staticClass: "ui-border-line ui-h-1",
              staticStyle: { height: "10px" }
            })
          ])
        : _vm._e(),
      _vm.artistData.playDesc
        ? [
            _c(
              "div",
              {
                staticClass: "profile-teamDesc",
                class: { full: _vm.showPlayDescAllText }
              },
              [
                _c("h4", [_vm._v("활동 경력")]),
                _c("div", { staticClass: "teamDesc-block" }, [
                  _c("div", { ref: "PlayDesc", staticClass: "teamDesc-text" }, [
                    _vm._v(_vm._s(_vm.artistData.playDesc))
                  ]),
                  _vm.isOverPlayDescMinHeight
                    ? _c(
                        "div",
                        {
                          staticClass: "info-more",
                          staticStyle: {
                            "margin-top": "16px",
                            "padding-bottom": "24px"
                          }
                        },
                        [
                          _c("icon", {
                            style: { margin: "-4px auto" },
                            attrs: {
                              src: "img/artist/btn-info-more.svg",
                              width: "28px",
                              height: "28px",
                              cover: false
                            },
                            on: {
                              click: function() {
                                _vm.showPlayDescAllText = !_vm.showPlayDescAllText
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]
            ),
            _c("div", {
              staticClass: "ui-border-line ui-h-1",
              staticStyle: { height: "10px" }
            })
          ]
        : _vm._e(),
      _vm.artistData.cusFlag !== "장소"
        ? _c("div", [
            _c("div", { staticStyle: { padding: "20px 0" } }, [
              _c("h4", [_vm._v("WHY 헤이비글")]),
              _c("div", { staticStyle: { height: "10px" } }),
              _c(
                "div",
                {
                  style: {
                    fontSize: "1.9rem",
                    color: "#555",
                    lineHeight: "1.5em"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex mb-5" },
                    [
                      _c("icon", {
                        staticStyle: { "margin-right": "30px" },
                        attrs: {
                          src: "img/artist/why-heybeagle-1.png",
                          width: "62px",
                          height: "62px"
                        }
                      }),
                      _vm._m(1)
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex mb-5" },
                    [
                      _c("icon", {
                        staticStyle: { "margin-right": "30px" },
                        attrs: {
                          src: "img/artist/why-heybeagle-2.png",
                          width: "62px",
                          height: "62px"
                        }
                      }),
                      _vm._m(2)
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex mb-5" },
                    [
                      _c("icon", {
                        staticStyle: { "margin-right": "30px" },
                        attrs: {
                          src: "img/artist/why-heybeagle-3.png",
                          width: "62px",
                          height: "62px"
                        }
                      }),
                      _vm._m(3)
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex mb-5" },
                    [
                      _c("icon", {
                        staticStyle: { "margin-right": "30px" },
                        attrs: {
                          src: "img/artist/why-heybeagle-4.png",
                          width: "62px",
                          height: "62px"
                        }
                      }),
                      _vm._m(4)
                    ],
                    1
                  )
                ]
              )
            ])
          ])
        : _vm._e(),
      _c(
        "PageModal",
        {
          ref: "naverLiveModal",
          attrs: {
            hideCb: function() {
              _vm.naverLiveModal.url = ""
            }
          }
        },
        [
          _c(
            "div",
            {
              style: {
                margin: "0 -3rem",
                height: "100%"
              },
              attrs: { slot: "body" },
              slot: "body"
            },
            [
              _vm.naverLiveModal.url
                ? _c("iframe", {
                    style: {
                      width: "100%",
                      height: "100%"
                    },
                    attrs: { src: _vm.naverLiveModal.url, frameborder: "0" }
                  })
                : _vm._e()
            ]
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [
      _vm._v("쇼핑 "),
      _c("span", { staticStyle: { color: "#f5331a" } }, [_vm._v("LIVE")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "color-lavender" }, [
        _vm._v("빠르고 쉬운 견적비교")
      ]),
      _c("br"),
      _vm._v(" 당신의 시간과 에너지를 아끼세요."),
      _c("br"),
      _vm._v(" 견적비교부터 결제까지 10분이면 충분해요. ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "color-lavender" }, [_vm._v("검증된 전문가")]),
      _c("br"),
      _vm._v(" 신원인증부터 경력, 프로필까지"),
      _c("br"),
      _vm._v(" 4단계 검증을 받은 전문가들을 만나보세요. ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "color-lavender" }, [
        _vm._v("대금 보호 시스템")
      ]),
      _c("br"),
      _vm._v(" 행사가 무사히 종료된 후 전문가에게"),
      _c("br"),
      _vm._v(" 지급하는 방식이라 환불도 쉽고 안전해요. ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "color-lavender" }, [
        _vm._v("노쇼, 불참, 분쟁 0건")
      ]),
      _c("br"),
      _vm._v(" 철저한 환불 및 취소, 보상금 제도가 있어"),
      _c("br"),
      _vm._v(" 안정된 섭외, 공연이 가능해요. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }