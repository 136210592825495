var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "container profile" },
    [
      _c("slick", {
        staticClass: "profile-images",
        attrs: { data: _vm.imgUrls }
      }),
      _c(
        "keep-alive",
        [
          _vm.sta === "profile"
            ? _c("a-profile", {
                attrs: {
                  "offer-data": _vm.offerData,
                  "artist-data": _vm.artistData,
                  "review-data": _vm.reviewData,
                  reviewItemData: _vm.reviewItemData,
                  "my-review-data": _vm.myReviewData,
                  "recomm-data": _vm.recommData,
                  "choice-artist-data": _vm.choiceArtistData,
                  reviewAvgCount: _vm.reviewAvgCount,
                  "is-preview-of-artist-profile": _vm.isPreviewOfArtistProfile,
                  "is-cus-order": _vm.offerUid
                },
                on: {
                  like: function(val) {
                    return (_vm.artistData.clientFav = val)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.isHideFooter === false
        ? [
            _vm.userData.category === "client"
              ? [
                  _vm.offerUid
                    ? [
                        _vm.isRejectedOffer === false
                          ? [
                              _vm.isPaidOffer
                                ? _c(
                                    "footer-box",
                                    {
                                      attrs: {
                                        single: "",
                                        "submit-text": "통화하기",
                                        "submit-cb": function() {
                                          return _vm.callToArtist()
                                        }
                                      }
                                    },
                                    [
                                      _c("icon", {
                                        style: {
                                          display: "inline-block",
                                          marginBottom: "-6px"
                                        },
                                        attrs: {
                                          slot: "submitLeftSlot",
                                          src: "img/common/call2.png",
                                          width: "3rem",
                                          height: "3rem",
                                          cover: false
                                        },
                                        slot: "submitLeftSlot"
                                      })
                                    ],
                                    1
                                  )
                                : _c("footer-box", {
                                    attrs: {
                                      single: false,
                                      "btn-text": _vm.$lib.isNewMembershipUser(
                                        _vm.artistData.newMemberDate
                                      )
                                        ? "채팅하기"
                                        : "문의하기",
                                      "btn-cb": function() {
                                        return _vm.gotoContactToArtist()
                                      },
                                      "btn-disabled":
                                        _vm.isPreviewOfArtistProfile,
                                      "submit-text": "예약하기",
                                      "submit-cb": function() {
                                        return _vm.toContact()
                                      },
                                      "submit-disabled":
                                        _vm.isPreviewOfArtistProfile ||
                                        [
                                          "ing",
                                          "wait",
                                          "selfEnd",
                                          "autoEnd"
                                        ].indexOf(_vm.offerData.nowState) ===
                                          -1,
                                      modalShow: _vm.footerModal.show,
                                      modalTitle: "스케줄 확인 후 결제하세요!",
                                      modalBody:
                                        "실시간으로 예약이 마감되고 있어요.\n'문의하기'로 일정 확인 후 결제해주세요.\n(결제는 한번 더 터치)",
                                      modalCb: function() {
                                        return _vm.toContact(true)
                                      }
                                    }
                                  })
                            ]
                          : _vm._e()
                      ]
                    : [
                        _vm.cusFlag === 1
                          ? _c("footer-box", {
                              attrs: {
                                single: false,
                                rate32: true,
                                "btn-text": "예약 문의하기",
                                "btn-cb": function() {
                                  return _vm.postOffer()
                                },
                                "submit-text": "결제하기",
                                "submit-cb": function() {
                                  return _vm.gotoPayment()
                                },
                                "submit-disabled": _vm.isPreviewOfArtistProfile
                              }
                            })
                          : _vm.isCustom &&
                            !_vm.isItem &&
                            _vm.artistData.expPurchaseBtnFlag == "y"
                          ? _c("footer-box", {
                              attrs: {
                                single: false,
                                rate32: true,
                                "btn-text":
                                  _vm.cusFlag == 0
                                    ? "1:1 문의하기"
                                    : "예약 문의하기",
                                "btn-cb": function() {
                                  return _vm.postOffer()
                                },
                                "submit-text": "구매하기",
                                "submit-cb": function() {
                                  return _vm.gotoPayment()
                                },
                                "submit-disabled": _vm.isPreviewOfArtistProfile
                              }
                            })
                          : _vm.isItem &&
                            _vm.artistData.expPurchaseBtnFlag == "n"
                          ? _c("footer-box", {
                              attrs: {
                                single: true,
                                "submit-text": "예약 문의하기",
                                "submit-cb": function() {
                                  return _vm.postOffer()
                                },
                                "submit-disabled": _vm.isPreviewOfArtistProfile
                              }
                            })
                          : !_vm.isItem && _vm.isShow
                          ? _c("footer-box", {
                              attrs: {
                                single: true,
                                "submit-text": "예약 문의하기",
                                "submit-cb": function() {
                                  return _vm.submit()
                                },
                                "submit-disabled": _vm.isPreviewOfArtistProfile
                              }
                            })
                          : _vm.isItem &&
                            _vm.artistData.expPurchaseBtnFlag == "y"
                          ? _c("footer-box", {
                              attrs: {
                                single: false,
                                rate32: true,
                                "btn-text": "예약 문의하기",
                                "btn-cb": function() {
                                  return _vm.postOffer()
                                },
                                "submit-text": "구매하기",
                                "submit-cb": function() {
                                  return _vm.itemPayment()
                                },
                                "submit-disabled": _vm.isPreviewOfArtistProfile
                              }
                            })
                          : _vm.isCustom && _vm.cusFlag === 8
                          ? _c("footer-box", {
                              attrs: {
                                single: true,
                                "submit-text": "예약 문의하기",
                                "submit-cb": function() {
                                  return _vm.postOffer()
                                },
                                "submit-disabled": _vm.isPreviewOfArtistProfile
                              }
                            })
                          : _c(
                              "footer-box",
                              {
                                attrs: {
                                  single: !_vm.isMember || !_vm.isDev,
                                  "btn-text": "통화하기",
                                  "btn-cb": function() {
                                    return _vm.callToArtist()
                                  },
                                  "submit-text":
                                    _vm.cusFlag === 2
                                      ? "예약 문의하기"
                                      : "1:1 섭외 문의",
                                  "submit-cb": function() {
                                    return _vm.postOffer()
                                  },
                                  "submit-disabled":
                                    _vm.isPreviewOfArtistProfile
                                }
                              },
                              [
                                _c("icon", {
                                  style: {
                                    display: "inline-block",
                                    marginRight: "8px",
                                    marginBottom: "-6px"
                                  },
                                  attrs: {
                                    slot: "btnLeftSlot",
                                    src: "img/common/call.png",
                                    width: "3rem",
                                    height: "3rem",
                                    cover: false
                                  },
                                  slot: "btnLeftSlot"
                                })
                              ],
                              1
                            )
                      ]
                ]
              : _vm.isGuest
              ? [
                  _c("footer-box", {
                    attrs: {
                      "submit-text": "헤이비글 앱에서 보기",
                      "submit-cb": function() {
                        return _vm.gotoApp()
                      }
                    }
                  })
                ]
              : _vm.isPreviewOfArtistMyOffer
              ? [
                  _c("footer-box", {
                    attrs: {
                      single: false,
                      btnText: "취소",
                      btnCb: function() {
                        return _vm.$router.back()
                      },
                      submitText: "견적 보내기",
                      submitCb: function() {
                        return _vm.$root.$emit("ApplyOffer:postOffer")
                      }
                    }
                  })
                ]
              : _vm.isPostProfile
              ? [
                  _c("footer-box", {
                    attrs: {
                      submitText: "심사 요청하기",
                      submitCb: function() {
                        if (_vm.postProfileModal.show) {
                          _vm.postProfile()
                          return
                        }
                        _vm.postProfileModal.show = true
                      },
                      modalShow: _vm.postProfileModal.show,
                      modalTitle: "작성된 내용 확인하셨나요?",
                      modalBody:
                        "가이드에 맞게 잘 작성되었는지\n다시 한번 확인하세요.\n맞지 않을 경우 심사가 거부돼요.\n(심사 요청은 한번 더 터치)",
                      modalCb: function() {
                        _vm.postProfileModal.show = false
                      }
                    }
                  })
                ]
              : _vm.isAbleChangePrice
              ? [
                  _c("footer-box", {
                    attrs: {
                      submitText: "견적 금액 수정",
                      submitCb: function() {
                        var applyUrl = {
                          path: "/offer/" + _vm.offerUid + "/apply",
                          query: {
                            mode: "change-price",
                            proPrice: _vm.artistData.proPrice
                          }
                        }
                        _vm.$router.push(applyUrl)
                      }
                    }
                  })
                ]
              : _vm._e()
          ]
        : _vm._e(),
      _vm.showOptionSelectModal
        ? _c("OptionSelect", {
            attrs: {
              payment: _vm.isPayment,
              isFixed: _vm.artistData.priceType == "fixed",
              isOptionTypeSingle: _vm.artistData.optionType == "single",
              option: _vm.artistData.optionData || [],
              extProd: _vm.artistData.extProdData || [],
              price: _vm.afterDiscountPaymentPrice,
              addWorkPrice: Number(_vm.artistData.addWorkPrice)
            },
            on: { close: _vm.modalClose, submit: _vm.submit }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }