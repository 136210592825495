var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "estimate" }, [
    _c("div", { staticClass: "estimate-item" }, [
      _c("h4", [_vm._v("지원 금액")]),
      _c("p", [
        _vm._v(_vm._s(_vm.$lib.addComma(_vm.artistData.proPrice || "0")) + "원")
      ])
    ]),
    _c("div", {
      staticClass: "ui-border-line ui-mt-3 ui-mb-3",
      staticStyle: { height: "10px" }
    }),
    _c("div", { staticClass: "estimate-item" }, [
      _c("h4", [_vm._v("지원 내용")]),
      _c(
        "p",
        { staticClass: "ui-preline" },
        [_c("url-in-text", { attrs: { text: _vm.artistData.proBodyText } })],
        1
      )
    ]),
    _c("div", {
      staticClass: "ui-border-line ui-mt-3 ui-mb-3",
      staticStyle: { height: "10px" }
    }),
    _c(
      "div",
      { staticClass: "estimate-item item-rating" },
      [
        _vm.userData.category === "client"
          ? [_c("h4", [_vm._v("견적 내용을 평가해주세요.")]), _vm._m(0)]
          : _vm._e(),
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("제안 금액")]),
              _c("star-rating", {
                staticClass: "rating",
                attrs: {
                  rating: _vm.formData.pointCount,
                  increment: 1,
                  "show-rating": false,
                  "star-size": 24,
                  "border-color": "#f5d03a",
                  "border-width": 4,
                  "inactive-color": "#fff",
                  "read-only": _vm.isArtist
                },
                on: {
                  "rating-selected": function(val) {
                    return (_vm.formData.pointCount = Number(val))
                  },
                  "current-rating": function(val) {
                    return (_vm.formData.pointCount = Number(val))
                  }
                }
              })
            ],
            1
          ),
          _c(
            "li",
            [
              _c("h5", [_vm._v("제안 내용")]),
              _c("star-rating", {
                staticClass: "rating",
                attrs: {
                  rating: _vm.formData.pointCount2,
                  increment: 1,
                  "show-rating": false,
                  "star-size": 24,
                  "border-color": "#f5d03a",
                  "border-width": 4,
                  "inactive-color": "#fff",
                  "read-only": _vm.isArtist
                },
                on: {
                  "rating-selected": function(val) {
                    return (_vm.formData.pointCount2 = Number(val))
                  },
                  "current-rating": function(val) {
                    return (_vm.formData.pointCount2 = Number(val))
                  }
                }
              })
            ],
            1
          ),
          _c(
            "li",
            [
              _c("h5", [_vm._v("프로필 만족도")]),
              _c("star-rating", {
                staticClass: "rating",
                attrs: {
                  rating: _vm.formData.pointCount3,
                  increment: 1,
                  "show-rating": false,
                  "star-size": 24,
                  "border-color": "#f5d03a",
                  "border-width": 4,
                  "inactive-color": "#fff",
                  "read-only": _vm.isArtist
                },
                on: {
                  "rating-selected": function(val) {
                    return (_vm.formData.pointCount3 = Number(val))
                  },
                  "current-rating": function(val) {
                    return (_vm.formData.pointCount3 = Number(val))
                  }
                }
              })
            ],
            1
          )
        ])
      ],
      2
    ),
    _c("div", {
      staticClass: "ui-border-line ui-mt-3",
      staticStyle: { height: "10px" }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("피드백을 분석하여"),
      _c("br"),
      _vm._v("맞춤형 전문가를 연결해드립니다!")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }