<template>
  <div class="container profile" ref="container">
    <slick class="profile-images" :data="imgUrls" />

    <!-- <template v-if="offerUid">
      <div class="ui-tab">
        <div class="ui-tab-item" :class="sta === 'estimate' ? 'on' : null" @click="sta = 'estimate'">견적서</div>
        <div class="ui-tab-item" :class="sta === 'profile' ? 'on' : null" @click="sta = 'profile'">프로필</div>
      </div>

import OptionSelect from './OptionSelect.vue'
      <div class="ui-border-line ui-mb-3"></div>
    </template>

    <keep-alive>
      <a-estimate v-if="sta === 'estimate'" :artist-data="artistData" />
    </keep-alive> -->

    <keep-alive>
      <a-profile
        v-if="sta === 'profile'"
        :offer-data="offerData"
        :artist-data="artistData"
        :review-data="reviewData"
        :reviewItemData="reviewItemData"
        :my-review-data="myReviewData"
        :recomm-data="recommData"
        :choice-artist-data="choiceArtistData"
        :reviewAvgCount="reviewAvgCount"
        :is-preview-of-artist-profile="isPreviewOfArtistProfile"
        :is-cus-order="offerUid"
        @like="(val) => (artistData.clientFav = val)"
      />
    </keep-alive>

    <template v-if="isHideFooter === false">
      <template v-if="userData.category === 'client'">
        <template v-if="offerUid">
          <template v-if="isRejectedOffer === false">
            <footer-box
              v-if="isPaidOffer"
              single
              submit-text="통화하기"
              :submit-cb="() => callToArtist()"
            >
              <icon
                slot="submitLeftSlot"
                src="img/common/call2.png"
                width="3rem"
                height="3rem"
                :cover="false"
                :style="{
                  display: 'inline-block',
                  marginBottom: '-6px',
                }"
              />
            </footer-box>
            <footer-box
              v-else
              :single="false"
              :btn-text="
                $lib.isNewMembershipUser(artistData.newMemberDate)
                  ? '채팅하기'
                  : '문의하기'
              "
              :btn-cb="() => gotoContactToArtist()"
              :btn-disabled="isPreviewOfArtistProfile"
              submit-text="예약하기"
              :submit-cb="() => toContact()"
              :submit-disabled="
                isPreviewOfArtistProfile ||
                ['ing', 'wait', 'selfEnd', 'autoEnd'].indexOf(offerData.nowState) === -1
              "
              :modalShow="footerModal.show"
              :modalTitle="'스케줄 확인 후 결제하세요!'"
              :modalBody="`실시간으로 예약이 마감되고 있어요.\n'문의하기'로 일정 확인 후 결제해주세요.\n(결제는 한번 더 터치)`"
              :modalCb="() => toContact(true)"
            >
            </footer-box>
            <!-- <footer-box
              v-else
              :triple="true"
              btn-text=""
              :btn-cb="() => callToArtist()"
              :btn-disabled="isPreviewOfArtistProfile"
              triple-btn-text=""
              :triple-btn-cb="() => createMsgRoom()"
              :triple-disabled="isPreviewOfArtistProfile"
              submit-text="안전 결제하기 >"
              :submit-cb="() => toContact()"
              :submit-disabled="isPreviewOfArtistProfile || ['ing', 'wait'].indexOf(offerData.nowState) === -1"
              :modalShow="footerModal.show"
              :modalTitle="'전문가님과 연락은 하셨나요?'"
              :modalBody="`스케줄 변동이 있을 수 있으니\n전문가님과 상의 후 진행해주세요.`"
              :modalCb="() => toContact(true)"
            >
              <icon
                slot="btnLeftSlot"
                src="img/common/call.png"
                width="4rem"
                height="4rem"
                :cover="false"
                :style="{
                  display: 'inline-block',
                  // marginRight: '8px',
                  marginBottom: '-9px',
                }"
              />
              <icon
                slot="tripleBtnLeftSlot"
                src="img/common/chat-ico-w.png"
                width="4rem"
                height="4rem"
                :cover="false"
                :style="{
                  display: 'inline-block',
                  // marginRight: '14px',
                  marginBottom: '-10px',
                }"
              />
            </footer-box> -->
            <!-- 결제 추가되기 전 footer -->
            <!-- <footer-box
              :single="false"
              btn-text="통화하기"
              :btn-cb="() => callToArtist()"
              :btn-disabled="isPreviewOfArtistProfile"
              submit-text="대화하기"
              :submit-cb="() => createMsgRoom()"
              :submit-disabled="isPreviewOfArtistProfile"
            >
              <icon
                slot="btnLeftSlot"
                src="img/common/call.png"
                width="3rem"
                height="3rem"
                :cover="false"
                :style="{
                  display: 'inline-block',
                  marginRight: '8px',
                  marginBottom: '-6px',
                }"
              />
              <icon
                slot="submitLeftSlot"
                src="img/common/chat-ico.png"
                width="3rem"
                height="3rem"
                :cover="false"
                :style="{
                  display: 'inline-block',
                  marginRight: '14px',
                  marginBottom: '-7px',
                }"
              />
            </footer-box> -->
          </template>
        </template>
        
        <template v-else>
          <!-- 커스텀 오더 구별자 장소 -->
          <footer-box
            v-if="cusFlag === 1"
            :single="false"
            :rate32="true"
            btn-text="예약 문의하기"
            :btn-cb="() => postOffer()"
            submit-text="결제하기"
            :submit-cb="() => gotoPayment()"
            :submit-disabled="isPreviewOfArtistProfile"
          >
          </footer-box>
          <!-- 커스텀 오더 구별자가 etc가 아니면서 구매하기 버튼 on -->
          <footer-box
            v-else-if="isCustom && !isItem && artistData.expPurchaseBtnFlag == 'y'"
            :single="false"
            :rate32="true"
            :btn-text="cusFlag == 0 ? '1:1 문의하기' : '예약 문의하기'"
            :btn-cb="() => postOffer()"
            submit-text="구매하기"
            :submit-cb="() => gotoPayment()"
            :submit-disabled="isPreviewOfArtistProfile"
          >
          </footer-box>
          <!-- 상품 옵션이 존재하면서 구매하기 버튼 OFF -->
          <footer-box
            v-else-if="isItem && artistData.expPurchaseBtnFlag == 'n'"
            :single="true"
            submit-text="예약 문의하기"
            :submit-cb="() => postOffer()"
            :submit-disabled="isPreviewOfArtistProfile"
          >
          </footer-box>
          <footer-box
            v-else-if="!isItem && isShow"
            :single="true"
            submit-text="예약 문의하기"
            :submit-cb="() => submit()"
            :submit-disabled="isPreviewOfArtistProfile"
          >
          </footer-box>
          <!-- 상품 옵션이 존재하면서 구매하기 버튼 ON -->
          <footer-box
            v-else-if="isItem && artistData.expPurchaseBtnFlag == 'y'"
            :single="false"
            :rate32="true"
            btn-text="예약 문의하기"
            :btn-cb="() => postOffer()"
            submit-text="구매하기"
            :submit-cb="() => itemPayment()"
            :submit-disabled="isPreviewOfArtistProfile"
          >
          </footer-box>

          <!-- '강연자' 예약 문의 화면 -->
          <footer-box
            v-else-if="isCustom && cusFlag === 8"
            :single="true"
            submit-text="예약 문의하기"
            :submit-cb="() => postOffer()"
            :submit-disabled="isPreviewOfArtistProfile"
          >
          </footer-box>
          <!-- TODO: isDev -->
          <footer-box
            v-else
            :single="!isMember || !isDev"
            btn-text="통화하기"
            :btn-cb="() => callToArtist()"
            :submit-text="cusFlag === 2 ? '예약 문의하기' : '1:1 섭외 문의'"
            :submit-cb="() => postOffer()"
            :submit-disabled="isPreviewOfArtistProfile"
          >
            <icon
              slot="btnLeftSlot"
              src="img/common/call.png"
              width="3rem"
              height="3rem"
              :cover="false"
              :style="{
                display: 'inline-block',
                marginRight: '8px',
                marginBottom: '-6px',
              }"
            />
          </footer-box>
        </template>
      </template>
      <template v-else-if="isGuest">
        <footer-box submit-text="헤이비글 앱에서 보기" :submit-cb="() => gotoApp()">
        </footer-box>
      </template>
      <template v-else-if="isPreviewOfArtistMyOffer">
        <footer-box
          :single="false"
          btnText="취소"
          :btnCb="() => $router.back()"
          submitText="견적 보내기"
          :submitCb="() => $root.$emit('ApplyOffer:postOffer')"
        />
      </template>
      <template v-else-if="isPostProfile">
        <footer-box
          submitText="심사 요청하기"
          :submitCb="
            () => {
              if (postProfileModal.show) {
                postProfile();
                return;
              }
              postProfileModal.show = true;
            }
          "
          :modalShow="postProfileModal.show"
          :modalTitle="'작성된 내용 확인하셨나요?'"
          :modalBody="`가이드에 맞게 잘 작성되었는지\n다시 한번 확인하세요.\n맞지 않을 경우 심사가 거부돼요.\n(심사 요청은 한번 더 터치)`"
          :modalCb="
            () => {
              postProfileModal.show = false;
            }
          "
        />
      </template>
      <template v-else-if="isAbleChangePrice">
        <footer-box
          submitText="견적 금액 수정"
          :submitCb="
            () => {
              const applyUrl = {
                path: `/offer/${offerUid}/apply`,
                query: {
                  mode: 'change-price',
                  proPrice: artistData.proPrice,
                },
              };
              $router.push(applyUrl);
            }
          "
        />
      </template>
    </template>
    <OptionSelect
      v-if="showOptionSelectModal"
      :payment="isPayment"
      :isFixed="artistData.priceType == 'fixed'"
      :isOptionTypeSingle="artistData.optionType == 'single'"
      :option="artistData.optionData || []"
      :extProd="artistData.extProdData || []"
      :price="afterDiscountPaymentPrice"
      :addWorkPrice="Number(artistData.addWorkPrice)"
      @close="modalClose"
      @submit="submit"
    ></OptionSelect>
  </div>
</template>

<script>
import Slick from "@/components/slick/Slick";
import AProfile from "@/components/artist/AProfile";
import AEstimate from "@/components/artist/AEstimate";
import FooterBox from "@/components/common/FooterBox";
import Icon from "@/components/common/Icon";
import LabelButton from "@/components/common/LabelButton";
import deepLink from "@/lib/deepLink.js";
import { usePostOfferStore } from "@/pinia/modules/post-offer";
import OptionSelect from "./OptionSelect.vue";
import {
  isDiscountEventCustomArtist
} from '@/lib/artist.js'

export default {
  name: "ArtistProfile",
  components: {
    Slick,
    AProfile,
    AEstimate,
    FooterBox,
    Icon,
    LabelButton,
    OptionSelect,
  },
  metaInfo() {
    const { teamName, imgUrl1, teamImgUrl } = this.artistData;
    const imgUrl = imgUrl1 || teamImgUrl;
    return {
      title: teamName || "전문가",
      titleTemplate: "헤이비글 - %s",
      meta: [
        {
          property: "og:title",
          content: teamName || "전문가",
          template: "헤이비글 - %s",
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: teamName || "전문가",
          template: '"%s"님을 소개합니다.',
          vmid: "og:description",
        },
        {
          property: "og:url",
          content: location.href,
          vmid: "og:url",
        },
        {
          property: "og:image",
          content: imgUrl,
          vmid: "og:image",
        },
      ],
    };
  },
  data() {
    return {
      sta: "profile",
      artistData: {},
      offerData: {},
      myReviewData: null,
      reviewData: [],
      reviewItemData: [],
      recommData: [],
      choiceArtistData: [],
      reviewAvgCount: {
        pointCountAvg: 0,
        pointCountAvg2: 0,
        pointCountAvg3: 0,
      },
      que: {
        artistData: false,
      },
      footerModal: {
        show: false,
        confirm: false,
      },
      postProfileModal: {
        show: false,
      },
      isExistsOfferData: false,
      showOptionSelectModal: false,
      isPayment: false,
    };
  },
  computed: {
    paymentPrice() {
      if (this.isCustom || this.isItem) {
        return Number(this.artistData.cusPriceStart);
      }
      return Number(this.artistData.proPrice);
    },
    afterDiscountPaymentPrice() {
      return this.$lib.floor(this.paymentPrice  - this.$lib.floor(this.paymentPrice * (this.artistSalePer) / 100), 2);
    },
    isDiscountEventCustomArtist() {
      return isDiscountEventCustomArtist(this.artistData)
    },
    artistSalePer() {
      const salePer = Number(this.artistData?.salePer) || 0;
      const useSalePer = [
        () => this.isEarlybirdEventArtist,
        () => (this.isCustom || this.isItem) && this.isDiscountEventCustomArtist,
      ].some((test) => test());

      if (useSalePer) {
        return salePer;
      }

      return 0;
    },
    imgUrls: {
      get() {
        return Object.keys(this.artistData).reduce((result, key) => {
          return /^imgUrl[0-9]{1,2}$/.test(key) && this.artistData[key]
            ? result.concat(this.artistData[key])
            : result;
        }, []);
      },
    },
    offerUid: {
      get() {
        return this.$route.params.offerUid;
      },
    },
    artistidx() {
      return this.$route.params.artistidx;
    },
    userData: {
      get() {
        return this.$store.state.user.userData;
      },
    },
    isGuest() {
      return !("useridx" in this.userData) || this.userData.useridx == 0;
    },
    isClient: {
      get() {
        return this.userData.category === "client";
      },
    },
    isArtist: {
      get() {
        return this.userData.category === "artist";
      },
    },
    isPreviewOfArtistProfile: {
      get() {
        return /preview$/.test(this.$route.path);
      },
    },
    isPreviewOfArtistMyOffer: {
      get() {
        return /\/artist\/my-offer\/preview\/[0-9]+$/.test(this.$route.path);
      },
    },
    isMember() {
      // if (this.artistData && Number(this.artistData.memberDate) > 0 && Number(this.artistData.memberDate) * 1000 > Date.now()) {
      //   return true
      // }
      return false;
    },
    cusFlag() {
      return this.$config.constant.cusFlag.indexOf(this.artistData.cusFlag);
    },
    isCustom() {
      if (this.cusFlag === 5) {
        return false;
      }
      return this.cusFlag >= 2 || this.cusFlag == 0;
    },
    isItem() {
      return this.artistData.categoryType != "profile" && (this.artistData?.optionData?.length || this.artistData?.extProdData?.length);
    },
    isShow() {
      return this.artistData.categoryType == "show";
    },
    isDev() {
      return process.env.NODE_ENV !== "production";
    },
    isRejectedOffer() {
      return this.offerData?.proMode === "reject";
    },
    isPaidOffer() {
      const condition = {
        default: [
          () =>
            ["ing", "selfEnd", "autoEnd", "cancel"].indexOf(this.offerData?.nowState) ===
            -1,
          () => this.offerData?.payStatus === "00",
        ],
        artist: [() => this.userData?.artistidx == this.offerData?.artistidx],
        client: [
          () => this.userData?.useridx == this.offerData?.useridx,
          () => this.artistidx === this.offerData?.artistidx,
        ],
      };
      if (condition.default.every((fn) => fn())) {
        return condition[this.isArtist ? "artist" : "client"].every((fn) => fn());
      }

      return false;
    },
    isHideFooter() {
      return this.$route.query?.hideFooter === "true";
    },
    isPostProfile() {
      return this.$route.query?.postProfile === "true";
    },
    /** 전문가가 견적 금액 변경할 수 있는 여부 */
    isAbleChangePrice() {
      if (this.$route.query?.ableChangePrice !== "true") {
        return false;
      }

      const conditions = [
        this.isMyProfile,
        this.isArtist,
        this.$route.name === "AEProfile",
        !this.isPaidOffer,
        !this.isRejectedOffer,
      ];

      return conditions.every((val) => val);
    },
    isMyProfile() {
      return this.userData.artistidx === this.artistidx;
    },
    remainDays() {
      const now = moment(moment(new Date()).format("YYYY-MM-DD"));
      const end = moment(
        moment(Number(this.offerData.playDate) * 1000).format("YYYY-MM-DD")
      );
      return end.diff(now, "days");
    },
    profilePercent() {
      return this.$store.state.user.artistProfilePercent;
    },
    feeInformationAndAgreement: {
      get: () => {
        return sessionStorage.getItem("feeInformationAndAgreement") > 0;
      },
      cache: false,
    },
  },
  watch: {
    $route(newRoute, oldRoute) {
      if (
        newRoute.params.artistidx !== oldRoute.params.artistidx ||
        newRoute.params.offerUid !== oldRoute.params.offerUid
      ) {
        this.setInit();
      }
    },
  },
  async created() {
    await this.setInit();
  },
  methods: {
    async setInit() {
      this.isExistsOfferData = false;

      if (this.isPreviewOfArtistProfile) {
        let artistData = this.$store.state.user.artistData;
        this.artistData = artistData;
      } else {
        await this.getProfile();
      }

      if (this.offerUid) {
        this.getOffer();
        // this.sta = 'estimate'
        if (this.isClient) {
          localStorage.setItem(`read:estimate:${this.offerUid}:${this.artistidx}`, "1");
        }
      }

      this.$refs?.container?.scrollTo?.(0, 0);

      if(!this.isGuest) {
        this.isExistsOfferData = await this.existsOfferData();
      }


    },
    getProfile() {
      if (this.que.artistData) return;
      this.que.artistData = true;

      const req = {
        method: "get",
        url: `/artist/profile/${this.artistidx}`,
        params: {},
      };

      if (this.offerUid && this.offerUid !== "preview") {
        req.method = "post";
        req.data = {
          offerUid: this.offerUid,
        };
        if (this.isClient) req.data.offerRead = "y";
      } else if (this.$route.query.main_menu_artist_uid) {
        req.params.main_menu_artist_uid = this.$route.query.main_menu_artist_uid;
      }

      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data);

          const resultData = this.$lib.resultCheck(data.resultData);

          if (resultData.success) {
            let artistData = data.response.artistData || {};

            if (!artistData.artistidx) {
              this.$router.back();
              return;
            }

            this.artistData = artistData;
            this.myReviewData = data.response.myReviewData || null;
            this.reviewData = data.response.reviewData || [];
            this.reviewItemData = data.response.reviewItemData || [];
            this.recommData = data.response.recommData || [];

            this.choiceArtistData = data.response.choiceArtistData || [];
            this.reviewAvgCount = data.response.reviewAvgCount || {};

            sessionStorage.setItem("artistData", JSON.stringify(this.artistData));
            sessionStorage.setItem("reviewItemData", JSON.stringify(this.reviewItemData));
            sessionStorage.setItem("myReviewData", JSON.stringify(this.myReviewData));

            // "의뢰 지원하기" 미리보기
            let previewEstimateData = sessionStorage.getItem("previewEstimate");
            if (this.offerUid === "preview" && previewEstimateData) {
              previewEstimateData = JSON.parse(previewEstimateData);
              if (previewEstimateData?.offerUid)
                this.artistData.offerUid = previewEstimateData.offerUid;
              if (previewEstimateData?.proPrice)
                this.artistData.proPrice = previewEstimateData.proPrice;
              if (previewEstimateData?.proBodyText)
                this.artistData.proBodyText = previewEstimateData.proBodyText;
              if (previewEstimateData?.proExt1)
                this.artistData.proExt1 = previewEstimateData.proExt1;
              if (previewEstimateData?.proExt2)
                this.artistData.proExt2 = previewEstimateData.proExt2;
            //   if (previewEstimateData?.proExt3)
            //     this.artistData.proExt3 = previewEstimateData.proExt3;
              if (previewEstimateData?.proExt4)
                this.artistData.proExt4 = previewEstimateData.proExt4;
              if (previewEstimateData?.proExt5)
                this.artistData.proExt5 = previewEstimateData.proExt5;
              if (previewEstimateData?.proExt6)
                this.artistData.proExt6 = previewEstimateData.proExt6;
              if (previewEstimateData?.proExt7)
                this.artistData.proExt7 = previewEstimateData.proExt7;
              if (previewEstimateData?.proExt8)
                this.artistData.proExt8 = previewEstimateData.proExt8;
              sessionStorage.removeItem("previewEstimate");
            }
          } else {
            this.$alert(resultData);
          }
        })
        .catch(log.error)
        .finally(() => {
          this.que.artistData = false;
        });
    },
    getOffer() {
      if (!this.offerUid || isNaN(this.offerUid)) return;

      const req = {
        method: "get",
        url: `/client/offer/${this.offerUid}`,
      };

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data);

          const resultData = this.$lib.resultCheck(data.resultData);

          if (resultData.success) {
            let offerData = data.response.offerData || {};

            if (!offerData.offerUid) {
              this.$router.back();
              return;
            }

            this.offerData = offerData;
          } else {
            this.$alert(resultData);
          }
        })
        .catch(log.error);
    },
    createMsgRoom() {
      const req = {
        method: "post",
        url: `/chat/${this.offerUid}`,
        data: {
          useridx: this.userData.useridx,
          targetUseridx: this.artistData.artistidx,
          targetTeamName: this.artistData.teamName,
        },
      };

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data);

          const resultData = this.$lib.resultCheck(data.resultData);

          if (resultData.success) {
            this.$router.push(`/message/${this.offerUid}/${this.artistData.artistidx}`);
          } else {
            this.$alert(resultData);
          }
        })
        .catch(log.error);
    },
    gotoPayment() {
      if (this.offerUid) {
        sessionStorage.setItem(
        `artistData:${this.artistData.artistidx}:${this.offerUid}`,
        JSON.stringify(this.artistData)
      );
        this.$router.push(`/payment/${this.offerUid}/${this.artistData.artistidx}`);
      } else {
        sessionStorage.setItem(`artistData:${this.artistData.artistidx}`,JSON.stringify(this.artistData));
        this.$router.push(`/payment/custom/${this.artistData.artistidx}`);
      }
    },
    itemPayment() {
      this.isPayment = true;
      if (this.artistData.optionData.length || this.artistData.extProdData.length) {
        this.showOptionSelectModal = true;
      } else {
        this.submit();
      }
    },
    postOffer() {
      FB.AppEvents.logEvent("click", {
        desc: `일반회원 > 전문가 상세 > 1:1의뢰 (예약 문의하기) 버튼 선택 시`,
      });
      this.$store.commit("resetPostOffer", {});
      if (this.isItem) {
        if (this.artistData.optionData.length || this.artistData.extProdData.length) {
          this.showOptionSelectModal = true;
          return;
        }
        this.submit();
        return;
      }
      if (this.cusFlag !== 0 ) {
        let cusType;
        if(this.cusFlag === 8) {
            cusType = "lecture";  
        } else if (this.cusFlag === 1) {
          cusType = "place";
        } else if (this.cusFlag === 5) {
          cusType = "etc";
        } else if (this.cusFlag > 1) {
          cusType = "custom";
        }

        if (cusType) {
          sessionStorage.setItem(
            `artistData:${this.artistData.artistidx}`,
            JSON.stringify(this.artistData)
          );
          this.$router.push(`/offer/contact/${cusType}/${this.artistData.artistidx}`);
          return;
        }
      }


      const isStarArtist = this.artistData?.starCheck === "y";
      let _wantPrice = parseFloat(this.artistData.wantPrice ?? null);

      if (isStarArtist) {
        if (isNaN(_wantPrice)) {
          _wantPrice = null;
        }
        if (_wantPrice >= 50000000) {
          _wantPrice = 10000000;
        }
      } else {
        _wantPrice = null;
      }

      this.$store.commit("setPostOffer", {
        mode: "direct",
        artistidx: this.artistData.artistidx,
        genre: this.artistData.genre,
        _wantPrice,
      });
      usePostOfferStore().$reset();
      usePostOfferStore().setArtist(this.artistData);

      if (this.isExistsOfferData) {
        this.$router.push({
          name: "ClientRegisteredOffer",
        });
        return;
      }

      this.$router.push(`/offer/post/0`);
    },
    callToArtist() {
      gtag("event", "user2pro_call_b");
      this.callCheck(this.artistData.artistidx);

      if (this.artistData.phone) {
        location.href = "tel:" + this.artistData.phone;
      } else {
        this.$toast("전화번호가 등록되지 않은 전문가입니다.");
      }
    },
    callCheck(artistidx) {
      const req = {
        method: "get",
        url: `/artist/telCount/${artistidx}`,
      };
      this.$http(req);
    },
    // 섭외하기
    toContact(confirm = false) {
      // if (this.remainDays > 90) {
      //   this.$store.commit('setModal', {
      //     show: true,
      //     body: '남은 행사일이 90일 이하인 경우에만 예약이 가능합니다.',
      //     confirm: '확인',
      //     useCancel: false,
      //   })
      //   return
      // }

      if (confirm) {
        this.footerModal.confirm = true;
        this.footerModal.show = false;
      } else if (!this.footerModal.confirm) {
        this.footerModal.show = true;
        this.footerModal.confirm = true;
      } else {
        this.gotoPayment();
      }
    },
    gotoApp() {
      deepLink(`/artist/profile/${this.artistidx}`);
    },
    gotoContactToArtist() {
      if (this.$lib.isNewMembershipUser(this.artistData.newMemberDate)) {
        this.createMsgRoom();
        return;
      }

      this.$router.push({
        path: `/etc/contact-to-artist/${this.offerUid}`,
        query: {
          artistidx: this.artistData.artistidx,
          teamName: this.artistData.teamName,
          teamImgUrl: this.artistData.teamImgUrl,
        },
      });
    },
    postProfile() {
      FB.AppEvents.logEvent("click", {
        desc: `전문가 > 프로필 등록 > 등록 요청하기 버튼 선택 시`,
      });

      const req = {
        method: "put",
        url: `/artist/profile/step2/${this.artistData.artistidx}`,
      };

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data);

          const resultData = this.$lib.resultCheck(data.resultData);

          if (resultData.success) {
            // this.$refs.pageModal.show()
            this.$router.push("/home", () => {
              this.$store.commit("setModal", {
                show: true,
                title: "등록 신청 완료!",
                body:
                  "심사가 완료되면 자동 노출되고\n알림으로도 알려드려요.\n(평일 기준, 최대 24시간 소요)",
                useCancel: false,
              });
            });
          } else {
            this.$alert(resultData);
          }
        })
        .catch(log.error);
    },
    existsOfferData() {
      const req = {
        method: "post",
        url: `/client/myOffer/0/1`,
        data: {
          mode: "",
          profile: "y",
          proPrice: "n",
          nowState: "ing,cancel,wait,selfEnd,autoEnd,end",
        },
      };

      return this.$http(req)
        .then(({ data }) => {
          const resultData = this.$lib.resultCheck(data.resultData);
          const offerData = data?.response?.offerData || [];

          return resultData.success && offerData.length > 0;
        })
        .catch(() => false);
    },
    modalClose() {
      this.showOptionSelectModal = false;
      this.isPayment = false;
    },
    submit(selectedData) {
      sessionStorage.setItem(
        `artistData:${this.artistData.artistidx}`,
        JSON.stringify(this.artistData)
      );
      if (this.isItem && (selectedData?.length)) {
        let selectedOptionData = selectedData.filter(data => data.isOption);
        let selectedExtProdData = selectedData.filter(data => !data.isOption);

        localStorage.setItem(
          `artistOption:${this.artistData.artistidx}`,
          JSON.stringify(selectedOptionData)
        );

        localStorage.setItem(
          `extProd:${this.artistData.artistidx}`,
          JSON.stringify(selectedExtProdData)
        );
      }else {
        localStorage.setItem(
          `artistOption:${this.artistData.artistidx}`,
          ''
        );

        localStorage.setItem(
          `extProd:${this.artistData.artistidx}`,
            ''
        );
      }
      if (this.isPayment) {
        this.gotoPayment();
      } else {
        this.$router.push(`/offer/contact/custom/${this.artistData.artistidx}`);
      }
    },
  },
};
</script>
