var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.src
    ? _c("div", { staticClass: "embed-video" }, [
        _c("div", { staticClass: "embed-video-container" }, [
          _c("iframe", { attrs: { src: _vm.src, frameborder: "0" } })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }