<template>
  <div class="estimate">
    <div class="estimate-item">
      <h4>지원 금액</h4>
      <p>{{$lib.addComma(artistData.proPrice || '0')}}원</p>
    </div>

    <div class="ui-border-line ui-mt-3 ui-mb-3" style="height: 10px"></div>

    <div class="estimate-item">
      <h4>지원 내용</h4>
      <p class="ui-preline"><url-in-text :text="artistData.proBodyText" /></p>
    </div>

    <div class="ui-border-line ui-mt-3 ui-mb-3" style="height: 10px"></div>

    <div class="estimate-item item-rating">
      <template v-if="userData.category === 'client'">
        <h4>견적 내용을 평가해주세요.</h4>
        <p>피드백을 분석하여<br>맞춤형 전문가를 연결해드립니다!</p>
      </template>

      <ul>
        <li>
          <h5>제안 금액</h5>
          <star-rating
            class="rating"
            :rating="formData.pointCount"
            @rating-selected="(val) => formData.pointCount = Number(val)"
            @current-rating="(val) => formData.pointCount = Number(val)"
            :increment="1"
            :show-rating="false"
            :star-size="24"
            border-color="#f5d03a"
            :border-width="4"
            inactive-color="#fff"
            :read-only="isArtist"
          >
          </star-rating>
        </li>
        <li>
          <h5>제안 내용</h5>
          <star-rating
            class="rating"
            :rating="formData.pointCount2"
            @rating-selected="(val) => formData.pointCount2 = Number(val)"
            @current-rating="(val) => formData.pointCount2 = Number(val)"
            :increment="1"
            :show-rating="false"
            :star-size="24"
            border-color="#f5d03a"
            :border-width="4"
            inactive-color="#fff"
            :read-only="isArtist"
          >
          </star-rating>
        </li>
        <li>
          <h5>프로필 만족도</h5>
          <star-rating
            class="rating"
            :rating="formData.pointCount3"
            @rating-selected="(val) => formData.pointCount3 = Number(val)"
            @current-rating="(val) => formData.pointCount3 = Number(val)"
            :increment="1"
            :show-rating="false"
            :star-size="24"
            border-color="#f5d03a"
            :border-width="4"
            inactive-color="#fff"
            :read-only="isArtist"
          >
          </star-rating>
        </li>
      </ul>
    </div>

    <div class="ui-border-line ui-mt-3" style="height: 10px"></div>

  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import Modal from '@/components/common/Modal'
import UrlInText from '@/components/common/UrlInText'

export default {
  components: {
    StarRating,
    Modal,
    UrlInText,
  },
  props: {
    artistData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const artistData = this.artistData || {}

    return {
      formData: {
        pointCount: Number(artistData.proPointCount) || 0,
        pointCount2: Number(artistData.proPointCount2) || 0,
        pointCount3: Number(artistData.proPointCount3) || 0,
      },
      isDev: false,
    }
  },
  computed: {
    pointCountOk: {
      get() {
        return (
          this.isArtist ||
          (this.formData.pointCount > 0 &&
            this.formData.pointCount2 > 0 &&
            this.formData.pointCount3 > 0)
        )
      },
    },
    isArtist() {
      return this.userData.category === 'artist'
    },
    userData: {
      get() {
        return this.$store.state.user.userData
      },
    },
  },
  watch: {
    pointCountOk(ok) {
      if (ok) {
        this.$toast('평가해주셔서 감사합니다')
      }
    },
    formData: {
      handler() {
        this.savePoint()
      },
      deep: true,
    },
  },
  mounted() {
    this.isDev = process.env.NODE_ENV === 'development'
  },
  beforeDestroy() {
    // sessionStorage.removeItem('offerData')
  },
  methods: {
    savePoint() {
      if (!this.pointCountOk) return

      const req = {
        method: 'post',
        url: `/client/offer/pointCount/${this.$route.params.offerUid}`,
        data: {
          pointCount: this.formData.pointCount,
          pointCount2: this.formData.pointCount2,
          pointCount3: this.formData.pointCount3,
          artistidx: this.$route.params.artistidx,
        },
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            log('변경됨')
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
  },
}
</script>


<style lang="scss" scoped>
.estimate {
  .estimate-item {
    h4 {
      padding: 2rem 0 0;
      font-size: 3rem;
      line-height: 4.5rem;
      font-weight: 400;
      color: #000;
    }
    p {
      font-size: 2rem;
      line-height: 3rem;
      color: #5a5a5a;
    }

    &.item-rating {
      text-align: center;

      p {
        color: #979797;
        margin-bottom: 4rem;
      }

      ul {
        width: 35rem;
        margin: 0 auto;
        li {
          overflow: hidden;
          text-align: left;
          margin-bottom: 2rem;

          > * {
            float: left;
          }

          h5 {
            margin: 0;
            padding-left: 3rem;
            width: 16rem;
            font-weight: 400;
            color: #5a5a5a;
            font-size: 2rem;
            line-height: 4rem;
          }
        }
      }
    }
  }
}
</style>
