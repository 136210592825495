import 'expose-loader?$!expose-loader?jQuery!jquery'

const android_id = "com.wimz.beagle"
const ios_id = "id1339958152"

let ua = navigator.userAgent
let supportDevicesRegEx = /Android|iPhone|iPad/i
let supportDevice = ua.match(supportDevicesRegEx)

const deepLink = function (url, cb) {
  const web_link = url ? `?webLink=${encodeURI(`/${url.replace(/^\//, '')}`)}` : ''

  const androidAppLink = 'heybeagle://beagle' + web_link
  const androidIntentUrl = 'intent://beagle' + web_link + '#Intent;scheme=heybeagle;package=' + android_id + ';end'
  const iosAppLink = 'heybeagle://beagle' + web_link
  const playStorePrefix = 'https://play.google.com/store/apps/details?id=' + android_id + '&hl=ko'
  const appStorePrefix = 'https://itunes.apple.com/kr/app/' + ios_id + '?mt=8'

  if (supportDevice !== null) {
    let deviceName = supportDevice[0]
    launchApp(deviceName)
  } else {
    gourl(playStorePrefix)
  }

  function gourl(url) {
    window.location.href = url
    typeof cb === 'function' && cb(url)
  }

  function launchApp(deviceName) {
    let isIOS = 'Android' != deviceName ? true : false
    if (!isIOS && isChromeAndBigThanChromeVersion18()) {
      gourl(androidIntentUrl)
      return;
    } else if (isIOS && isBigThanIOS9()) {
      gourl(iosAppLink)
      failApp(isIOS)
    } else {
      let src = !isIOS ? androidAppLink : iosAppLink
      $('<iframe>').attr('src', src)
      failApp(isIOS)
    }
  }

  function isChromeAndBigThanChromeVersion18() {
    let ua = navigator.userAgent
    if (ua.indexOf('Chrome') == -1) {
      return false
    }

    let versionRegEx = /Chrome\/[0-9]+\.[0-9]+\.[0-9]+/
    let majorVersion = ua.match(versionRegEx).pop().split('/')[1]
    return parseInt(majorVersion) > 18 ? true : false
  }

  function isBigThanIOS9() {
    let ua = navigator.userAgent
    let versionRegEx = /iPhone OS [0-9]+/
    let version = ua.match(versionRegEx) || ''
    if (version) version = version.pop().split(' ')[2]
    let majorVersion = parseInt(version.split('_')[0])
    return majorVersion >= 9 ? true : false
  }

  function failApp(isIOS) {
    let storeURL = isIOS ? appStorePrefix : playStorePrefix
    let deLay = 500
    setTimeout(function () {
      window.location.href = storeURL
    }, deLay)
  }
}

export default deepLink
