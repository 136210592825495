<template>
  <div class="img-area">
    <PuSkeleton class="img-slider" :loading="true" :width="width_" :height="loadImgCnt == data.length ? '35.4rem' : '0'"></PuSkeleton>
    <div class="img-slider" :class="{'image-none': loadImgCnt == data.length}">
        <ul ref="slick" :style="{ height }">
          <li
              v-for="(row, key) in data"
              :key="key"
              class="ui-bg-img"
              @click="() => $refs.modal.show(key)"
              :style="{
              backgroundImage: `url(${$lib.cdnUrl(row)})`,
              backgroundPosotion: 'center',
              width: width_,
              height,
              }"
          >
          </li>
        </ul>

        <div class="current-no"><span class="current-page">{{currentNo}}</span> / {{data.length}}</div>

        <div class="btn-left-arrow" @click="slickPrev">
        <icon src="img/common/btn-left-arrow.png" width="4rem" height="4rem" :cover="false" />
        </div>

        <div class="btn-right-arrow" @click="slickNext">
        <icon src="img/common/btn-right-arrow.png" width="4rem" height="4rem" :cover="false" />
        </div>

        <!-- <slick-modal ref="modal" :images="data" /> -->
        <!-- <img-preloader :images="data" /> -->

        <PageModal ref="modal">
        <div slot="body" :style="{ margin: '0 -3rem' }">
            <template v-for="(row, key) in data">
            <img :key="key" :src="$lib.cdnUrl(row)" :alt="`image ${key + 1}`" width="100%">
            </template>
        </div>
        </PageModal>
    </div>
  </div>

    
</template>

<script>
// import SlickModal from '@/components/slick/SlickModal'
// import ImgPreloader from '@/components/utils/ImgPreloader'
import Icon from '@/components/common/Icon'
import PageModal from '@/components/common/PageModal'

export default {
  components: {
    // SlickModal,
    // ImgPreloader,
    Icon,
    PageModal,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
    },
    height: {
      type: String,
      default: '35.4rem',
    },
  },
  data: () => ({
    $slick: null,
    option: {
      arrows: false,
      speed: 100,
    },
    currentNo: 1,
    imageLoading:true,
    loadImgCnt: 0
  }),
  computed: {
    width_() {
      return this.width || this.$store.state.app.width + 'px'
    },
  },
  watch: {
    data(val){
      this.loadBackgroundImages(val);
    }
  },
  mounted() {
    this.loadBackgroundImages(this.data);

    this.$nextTick(() => {
      this.$slick = $(this.$refs.slick)

      this.$slick.on('afterChange', (event, slick, currentSlide) => {
        this.currentNo = currentSlide + 1
      })
    })

    
    
  },
  methods: {
    setSlick(option = this.option) {
      this.$slick.slick(option)
    },
    slickNext() {
      this.$slick.slick('slickNext')
    },
    slickPrev() {
      this.$slick.slick('slickPrev')
    },

    loadBackgroundImages(imgList) {
      if(imgList.length > 0) {
        let loadImgCnt = 0;
        imgList.forEach(imgUrl => {    
          if (imgUrl && imgUrl.length > 0) {
            this.$lib.loadImageWithSekeleton(this.$lib.cdnUrl(imgUrl))
              .then(() => {
                loadImgCnt+=1
                if(loadImgCnt == imgList.length) {
                  this.setSlick(this.option)
                }
              });
          }
          else {
            loadImgCnt+=1;
          }
        });
      }
      
    }
  },
}
</script>

<style lang="scss" scoped>
  .img-area{
    margin: -1.3rem 0;
  }
    
  .img-slider {
    margin: 0 -3rem;
    background-color: #ececec;
    overflow: hidden;
    position: relative;

    .current-no {
      position: absolute;
      right: 2rem;
      bottom: 2rem;
      border-radius: 26px;
      background-color: rgba($color: #000000, $alpha: 0.15);
      color: #fff;
      font-size: 1.4rem;
      text-align: center;
      padding: 0.8rem 1.2rem;
      .current-page {
        font-weight: 600;
      }
    }

    .btn-left-arrow,
    .btn-right-arrow {
      position: absolute;
      top: 50%;
      margin-top: -2.4rem;
    }

    .btn-left-arrow {
      left: 1rem;
    }

    .btn-right-arrow {
      right: 1rem;
    }
  }
  .image-none{ 
    height: 0;
  }
</style>
