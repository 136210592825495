<template>
    <div class="embed-video" v-if="src">
        <div class="embed-video-container">
            <iframe :src="src" frameborder="0" />
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
    props: {
        src: {
            type: String,
            required: true,
        },
    },
    methods: {
        getThumbnail(src: string) {
            // 썸네일 가져오는 로직

            return src
        }
    }
})
</script>

<style lang="scss">
.embed-video {
    .embed-video-container {
        width: 100%;
        height: 0;
        padding-bottom: 100% * (9 / 16);
        position: relative;

        > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
</style>
