<template>
    <footer-box
        :submit-text="`${payment ? (!isOptionSelect && option.length > 0) && (extProd.length > 0 && !isExtProdSelect) ? '0원 결제하기' : `${$lib.addComma(totalPrice)}원 결제하기` : '예약 문의하기'}`"
        :modalShow="isShow"
        class="select-option-modal"
        :modalCb="() => modalClose()"
        :submitCb="() => submit()"
        :submitDisabled="!isOptionSelect && option.length > 0"
    ><!-- 비활성화 case :submitDisabled="true" -->
        <template #modalBody>
            <div class="select-option-wrap">
                <div class="select-option-area" v-if="option.length > 0">
                    <div class="select-option-label">옵션 선택</div>
                    <div class="select-option-dropdown">
                        <div class="select-dropdown" :class="{'is-active':selectOptionGroup[index]}" v-for="(select, index) in option" :key="index">
                            <button type="button" class="select-dropdown-toggle" @click="openOptionSelect(index)">
                                <span>{{ selectOption[index] ? selectOption[index].value : select.label }}</span>
                                <icon src="img/common/chevron-down.png" width="3rem" height="3rem" :cover="false" :style="{ marginLeft: 'auto' }" />
                            </button>
                            <ul v-show="selectOptionGroup[index]" class="select-dropdown-content">
                                <li v-for="(item, i) in select.option" :key="i">
                                    <button type="button" class="select-dropdown-item" @click="openOptionSelect(index, i)">
                                        {{ item.value }}
                                        <span v-if="item.price && item.price > 0">(+ {{ $lib.addComma(item.price) }}원)</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="select-option-area" v-if="extProd.length > 0">
                    <div class="select-option-label">추가 상품</div>
                    <div class="select-option-dropdown">
                        <div class="select-dropdown" :class="{'is-active':selectExtProdGroup[index]}" v-for="(select, index) in extProd" :key="index">
                            <button type="button" class="select-dropdown-toggle" @click="openExtProdSelect(index)">
                                <span>{{ select.label }}</span>
                                <icon src="img/common/chevron-down.png" width="3rem" height="3rem" :cover="false" :style="{ marginLeft: 'auto' }" />
                            </button>
                            <ul v-show="selectExtProdGroup[index]" class="select-dropdown-content">
                                <li v-for="(item, i) in select.extProd" :key="i">
                                    <button type="button" class="select-dropdown-item" @click="addSelectedExtProd(index,i)">
                                        {{ item.value }}
                                        <span v-if="item.price && item.price > 0">(+ {{ $lib.addComma(item.price) }}원)</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <ul class="select-option-list">
                    <li class="select-option-item" v-for="(select, index) in selectItems" :key="index">
                        <div class="select-option-info">
                            <span>{{ select.label }}</span>
                            <button type="button" class="btn-close" @click="delCount(index)">
                                <icon src="img/common/cancel.png" width="3.4rem" height="3.4rem" :cover="false" />
                            </button>
                        </div>
                        <div class="select-option-count">
                            <div class="count-area" v-if="select.type ? select.type == 'quantity' : !isOptionTypeSingle">
                                <button type="button" class="btn-minus" @click="minusCount(select, index)">
                                    <icon src="img/common/btn-minus.png" width="2.5rem" height="2.5rem" :cover="false" />
                                </button>
                                <span v-if="select.count">{{ select.count }}</span>
                                <button type="button" class="btn-plus" @click="plusCount(select)">
                                    <icon src="img/common/btn-plus.png" width="2.5rem" height="2.5rem" :cover="false" />
                                </button>
                            </div>
                            <!-- 옵션 가격 : (상품가격(부가세 미포함) + 옵션 가격) * 갯수 -->
                            <div v-if="select.price" class="option-price">{{ $lib.addComma(selectItemPrice(select)) }}원</div>
                        </div>
                    </li>
                </ul>
                
            </div>
        </template>
        <template #submitRightSlot>
            <icon src="img/common/next-white.png" width="1rem" height="2rem" :cover="false" :style="{
                display: 'inline-block',
                marginLeft: '1.6rem',
            }"/>
        </template>
    </footer-box>
</template>

<script>
import FooterBox from '@/components/common/FooterBox.vue'
import Icon from '@/components/common/Icon'

export default {
    name: 'OptionSelect',
    components: {
        FooterBox,
        Icon
    },
    props: {
      isShow: {
        type: Boolean,
        default: true,
      },
      isFixed: {
        type: Boolean,
        default: true,
      },
      isOptionTypeSingle: {
        type: Boolean,
        default: false,
      },
      option: {
        type: Array,
        default: ()=>{
            return [];
        },
      },
      extProd: {
        type: Array,
        default: ()=>{
            return [];
        },
      },
      payment: {
        type: Boolean,
        default: false,
      },
      price: {
        type: Number,
        default: 0,
      },
      addWorkPrice: {
        type: Number,
        default: 0,
      }
    },
    
    data: () => ({
        optionItem: [],
        selectOptionGroup: {},
        selectExtProdGroup: {},
        selectItems: [],
        selectOption: {},
        selectExtProd: {},
        selectPay: 0,
        count: 1,
    }),
    methods: {
        openOptionSelect(index, targetIndex) {
            this.$set(this.selectOptionGroup, index, !this.selectOptionGroup[index])
            for(let i = 0; i < this.option.length;i++) {
                if(i != index) {
                    this.$set(this.selectOptionGroup, i, false);
                }
            }
            if (targetIndex != undefined){
                this.selectOption[index] = this.option[index].option[targetIndex];
            }
            const keys = Object.keys(this.selectOption);
            if(keys.length == this.option.length) {
                const item ={
                    label: [],
                    price: 0,
                    count: 1,
                    isOption: true,
                };
                for(let i =0; i < keys.length;i++) {
                    item.label.push(this.selectOption[keys[i]].value);
                    item.price += parseInt(this.selectOption[keys[i]].price);
                }
                item.label = item.label.join(' / ');

                if( this.selectItems.find(v => { return v.label === item.label }) ) {
                    this.$store.commit('setModal', {
                        show: true,
                        body: '이미 추가된 옵션입니다.',
                        useCancel: false,
                    })
                }else {
                    this.selectItems.push(item);
                }

                this.selectOption = {};
            }
        },
        
        openExtProdSelect(index) {
            this.$set(this.selectExtProdGroup, index, !this.selectExtProdGroup[index])
        },

        addSelectedExtProd(index,targetIndex) {
            let extProdInfo = this.extProd[index];
            this.selectExtProd =  extProdInfo.extProd[targetIndex];

            let optionValList = this.option.map(info => info.option.map(v => v.value)).flat();
            let extProdValList = this.extProd.map(info => info.extProd.map(v => v.value)).flat();
            
            let optionDupCnt = optionValList.filter(v => v == this.selectExtProd.value).length  
            let extProdDupCnt = extProdValList.filter(v => v == this.selectExtProd.value).length;

            const item ={
                label: (optionDupCnt >= 1 || extProdDupCnt > 1) ? this.selectExtProd.value + `(${extProdInfo.label})`  : this.selectExtProd.value,
                price: this.selectExtProd.price,
                type: this.selectExtProd.type,
                isOption: false,
                count: 1,
            };

            if(this.selectItems.find(v => { return v.isOption == item.isOption && v.label === item.label })) {
                this.$store.commit('setModal', {
                    show: true,
                    body: '이미 추가된 상품입니다.',
                    useCancel: false,
                })
            }else {
                this.selectItems.push(item);
            }
            
            this.$set(this.selectExtProdGroup, index, false);

            this.selectExtProd = {};
        },

        selectItemPrice(select) {
            if(!select.isOption) {
                return `+ ${select.price * select.count}`;
            }else {
                return !this.isFixed ? `+ ${select.price * select.count}` : (this.price + select.price) * select.count
            }
        },

        modalClose() {
            this.$emit('close');
        },
        minusCount(select, index) {
            select.count -= 1;
            if(select.count < 1) {
                this.selectItems.splice(index, 1);
            }
        },
        plusCount(select) {
            select.count += 1;
        },
        delCount(index) {
            this.selectItems.splice(index, 1);
        },
        submit() {
            this.$emit('submit', this.selectItems);
        }
    },
    computed: {
        totalPrice() {
            // 총 가격 : 선택한 옵션들의 총 가격 + 추가작업비용
            return Math.floor(this.selectItems.reduce((p,c,i)=>{
                if(c.isOption) {
                    return p +  ((this.price + c.price) * c.count);
                }else {
                    return p +  (c.price * c.count);
                } 
            }, (this.option.length > 0 ? 0 : this.price)) + this.addWorkPrice);
        },
        isOptionSelect() {
            const optionSelectItem = this.selectItems.filter(x => {
                    return x.isOption
            });
            return optionSelectItem.length > 0;
        },
        isExtProdSelect() {
            const extProdSelectItem = this.selectItems.filter(x => {
                    return !x.isOption
            });
            return extProdSelectItem.length > 0;
        }
    }
}
</script>

<style lang="scss" scoped>
.select-option-wrap {
    overflow-y: auto;
    max-height: calc(60vh - 9.3rem);
    padding: 0 2.4rem 4rem;
    margin-bottom: -4rem;
    .select-option-area {
        width: 100%;
        margin: 0 0 2.4rem;
        .select-option-label {
            margin-top: 3.2rem;
            margin-bottom: 1.8rem;
            font-weight: 600;
            font-size: 2.1rem;
            color: #222;
        }
        .select-option-dropdown {
            width: 100%;           
            .select-dropdown {
                position: relative;
                ~ .select-dropdown {
                    margin-top: 1.2rem;
                }
                &.is-active {
                    .select-dropdown-toggle {
                        border-radius: 0.7rem 0.7rem 0 0;
                        color: #ab54bb; 
                        .icon {
                            transform: rotate(180deg);
                        }
                    }
                    .select-dropdown-content {
                        display: block;
                        overflow-y: auto;
                        max-height: 22.5rem;
                    }
                }
                .select-dropdown-toggle {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 5.6rem;
                    padding: 0 1.4rem 0 2rem;
                    border-radius: 0.7rem;
                    border: 0.1rem solid #aaa;
                    background-color: #fff;
                    font-size: 2.1rem;
                    text-align: left;
                    color: #999;
                    &::after {
                        display: none;
                    }
                    span {
                        display: block;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .icon {
                        flex-shrink: 0;
                    }
                }
                .select-dropdown-content {
                    width: 100%;
                    margin-top: -0.1rem;
                    border: 0.1rem solid #aaa;
                    border-radius: 0 0 0.7rem 0.7rem;
                    background-color: #fff;
                    li {
                        &:first-child {
                            .select-dropdown-item {
                                border-top: 0;
                            }
                        }
                        &:last-child {
                            .select-dropdown-item {
                                border-radius: 0 0 0.7rem 0.7rem;
                            }
                        }
                    }
                    .select-dropdown-item {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        padding: 1.6rem 2rem;
                        border-top: 0.1rem solid #eee;
                        font-size: 1.9rem;
                        text-align: left;
                        span {
                            display: block;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
    .select-option-list {
        .select-option-item {
            margin-top: 1.5rem;
            padding: 1.6rem 1.2rem 1.6rem 2rem;
            background-color: #fafafa;
            border: 0.1rem solid #dddddd;
            border-radius: 1rem;
            &:first-child {
                margin-top: 0;
            }
            .select-option-info {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                font-size: 2.1rem;
                color: #3a3a3a;
                span {
                    display: block;
                    margin-top: 0.8rem;
                    padding-right: 1.6rem;
                    word-break: break-word;
                }
                .btn-close {
                    display: inline-flex;
                }
            }
            .select-option-count {
                display: flex;
                align-items: center;
                margin-top: 2rem;
                .count-area {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 12rem;
                    height: 4.4rem;
                    padding: 0 1rem;
                    border: 0.1rem solid #ddd;
                    border-radius: 0.4rem;
                    background-color: #fff;
                    font-size: 2.1rem;
                    .btn-minus,
                    .btn-plus {
                        display: inline-flex;
                    }
                }
                .option-price {
                    margin-left: auto;
                    font-weight: bold;
                    font-size: 2.1rem;
                    color: #3a3a3a;
                }
            }
        }
    }
}
</style>
